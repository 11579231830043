/* import react */
import React, {useState, useEffect, useRef} from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import FullscreenDimmer from './FullscreenDimmer';

import ErrorLabel from './ErrorLabel';

/**
 * Handle single file upload using semantic button and show result in modal
 * @param {{wrapperStyle: object, buttonColor: string, buttonText: string, disabled: boolean, formatErrorText: string, accept: string[], }} props 
 */
const FileInputInstantUpload = (props) => {
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [resultText, setResultText] = useState('資料更新中');
  const [dimmerOpen, setDimmerOpen] = useState(false);
  const fileInput = useRef(null);
  const { wrapperStyle, buttonColor, buttonText, disabled, formatErrorText, accept } = props;

  useEffect(()=>{
    setError(false);
  }, [disabled]);

  const reset = () => {
    setError(false);
    setDimmerOpen(false);
    if(props.noForm){
      fileInput.current.value = '';
    }else{
      fileInput.current.click();
    }
  }

  const handleSubmit = (event) => {
    console.log("handle submit");
    const f = async () => {
      event.preventDefault();
      if(!fileInput.current.files.length) return;
      if(accept.indexOf(fileInput.current.files[0].type) === -1){
        setError(true);
        return;
      }
      setError(false);
      setLoading(true);
      setDimmerOpen(true);
      setResultText('資料更新中');
      //fileHandling: processing, sending data and retrieve the result
      try{
        const result = await props.fileHandling(fileInput.current.files[0]);
        
        fileInput.current.form.reset();
        
        setLoading(false);
        if (result.status) {
          setResultText(<Header as='h2' icon><Icon name="check"/>{props.successMsg || "已成功更新資料庫資料並整理列表"}</Header>);
        }else{
          setResultText(
            <Header as='h2' icon>
              <Icon name="warning"/>
              {typeof result.err==='string' ? result.err : "更新資料庫時發生錯誤，已整理列表"}
            </Header>
          );
        }
      }catch(err){
        setLoading(false);
        setResultText(<Header as='h2' icon><Icon name="close"/>{typeof err==='string' ? err : "更新資料庫資料時發生錯誤"}</Header>);
      }finally{
        setTimeout(()=>{
          setError(false);
          setLoading(false);
          setDimmerOpen(false);
          setResultText('');
        }, 5000);
      }
    }
    f();
  }

  if(props.noForm){
    return (<>
      <div style={{ ...wrapperStyle, textAlign: 'center', padding: 0 }}>
        <Button
          color={buttonColor}
          content={buttonText}
          icon='upload'
          type='button'
          circular
          disabled={disabled}
          onClick={reset}
        />
        <input
          hidden
          type="file"
          accept={accept}
          ref={fileInput}
          onChange={handleSubmit}
        />
        {!!isError && <div><ErrorLabel message={formatErrorText} /></div>}
      </div>
      <FullscreenDimmer
        active={dimmerOpen}
        content={typeof resultText === 'string' ? <h2>{resultText}</h2> : resultText}
        isLoading={isLoading}
      />
    </>)
  }

  return (<>
    <form style={{ ...wrapperStyle, textAlign: 'center', padding: 0}}>
      <Button
        color={buttonColor}
        content={buttonText}
        icon='upload'
        type='button'
        circular
        disabled={disabled}
        onClick={reset}
      />
      <input
        hidden
        type="file"
        accept={accept}
        ref={fileInput}
        onChange={handleSubmit}
      />
      {!!isError &&<div><ErrorLabel message={formatErrorText} /></div>}
    </form>
    <FullscreenDimmer
      active={dimmerOpen}
      content={typeof resultText === 'string'?<h2>{resultText}</h2>:resultText}
      isLoading={isLoading}
    />
  </>)
}

export default FileInputInstantUpload;