import React, {memo} from 'react';
import StarRatings from 'react-star-ratings';
import pick from 'lodash/pick';

const KOLStarRatings = (props) =>{

    const trueProps = Object.assign({
      starRatedColor: 'orange',
      numberOfStars: 5,
      name: 'rating',
    }, pick(props, ['starDimension', 'starSpacing', 'name', 'changeRating']));

    if(props.hasOwnProperty('rating'))
      trueProps.rating = +(props.rating)

    return (<StarRatings
      {...trueProps}
    />);
}

export default memo(KOLStarRatings);