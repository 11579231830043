import React from 'react';
import find from 'lodash/find';
import pick from 'lodash/pick';

/* import components */
import {Buttons, ErrorLabel, ConfirmModal, _Component, MySortableTable} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import { selectOptions } from '../../Helper/Helper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Segment } from 'semantic-ui-react';

class category extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      superCategory: new Map(),
      isLoading: true,

      isEditModalOpen: false,
      isDeleteModalOpen: false,

      id: null,
      displayName: '',
      order: null,
      superCategoryId: null,
    }

    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        email: '請輸入有效的電郵',
        default: '請輸入資料'
      }
    });

    this.tableColumnData = [
      {
        headerName: '名稱',
        width: 5,
        cellRender: 'displayName',
        allowSort: true,
      },
      {
        headerName: '分類',
        width: 4,
        cellRender: x=>this.state.superCategory.get(x.superCategoryId),
        sortMethod: 'superCategoryId',
        allowSort: true,
      },
      {
        headerName: '順序',
        width: 2,
        cellRender: 'order',
        allowSort: true,
      },
      {
        headerName: '行動',
        width: 2,
        allowSort: false,
        cellRender: x => (<>
          <Buttons.EditButton
            data-id={x.id}
            data-modalname='isEditModalOpen'
            onClick={this.modalToggle}
            circular
            concise
          />
          <Buttons.DeleteButton
            data-id={x.id}
            data-modalname='isDeleteModalOpen'
            onClick={this.modalToggle}
            circular
            concise
          />
        </>),
        cellClassName: 'textlessCell',
      },
    ];
  }

  update = async () => {
    try{
      const [category, superCategory] = await Promise.all([
        this.assignPromise(get('getCategory/'), 'getCategory'),
        this.assignPromise(get('getSuperCategory/'), 'getSuperCategory'),
      ]);
      await this._setState({
        category,
        superCategory: new Map(superCategory.map(x=>[x.id, x.displayName])),
        isLoading: false,
      });
    }catch(err){
      if (!this.isCancelled(err)){
        console.log("Error when updating categories", err);
      }
    }
  }

  componentDidMount = () => {
    this.update();
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {inputType, stateName} = event.target.closest('.ui').dataset;
    let value = inputHandler(inputType, data);
    this.setState({ [stateName]: value })
  }

  modalToggle = (eventOrStateName) => {
    let modalname, id;
    if (typeof eventOrStateName === 'object') {
      let modalData = {};
      if (eventOrStateName.target.classList.contains('modals')) {
        modalData = eventOrStateName.target.firstElementChild.dataset;
      } else {
        modalData = eventOrStateName.target.closest('.ui, button').dataset;
      }
      modalname = modalData.modalname;
      id = +modalData.id;
    } else {
      modalname = eventOrStateName;
    }
    const ref = find(this.state.category, {id});
    this._setState({
      [modalname]: !this.state[modalname],
      id: id || null,
      displayName: ref ? ref.displayName : '',
      superCategoryId: ref ? ref.superCategoryId :null,
      order: ref ? ref.order : 999,
    });
  }

  /* save category */
  save = () => {
    if (this.validator.allValid()) {
      let data = pick(this.state, ['id', 'displayName', 'superCategoryId', 'order']);
      this._setState({isLoading: true});
      this.assignPromise(post('editCategory', data), 'setCategory').then((result) => {
        if (result && result.status) {
          this.update().then(() => {
            this._setState({
              isEditModalOpen: false,
              isLoading: false,
            });
          });
        } else {
          if(!result || typeof result !== 'object' || !result.isCanceled){
            alert('發生錯誤');
            console.log("Save Category Result", result);
          }
        }
      }).catch(_r => { });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  /* delete category */
  delete = () => {
    this.assignPromise(post('deleteCategory', { id: this.state.id }), 'setCategory').then((result) => {
      if (result && result.status) {
        this.update().then(() => {
          this._setState({
            isDeleteModalOpen: false,
          });
        });
      } else {
        if(!result || typeof result !== 'object' || !result.isCanceled){
          alert('發生錯誤');
          console.log("Delete Category Result", result);
        }
      }
    }).catch(_r => { });
  }

  render() {
    const {
      category,
      isEditModalOpen,
      isDeleteModalOpen,
      superCategory,
      displayName,
      order,
      superCategoryId,
      isLoading,
    } = this.state;

    const options = selectOptions(Array.from(superCategory), '1', '0');
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Button color='orange' content='新增分類' icon='add' floated='right' data-modalname='isEditModalOpen' onClick={this.modalToggle} circular />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MySortableTable
                data={category}
                tableColumnData={this.tableColumnData}
                finishedLoading={!isLoading}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal open={isEditModalOpen} closeOnDimmerClick={false} data-modalname='isEditModalOpen' onClose={this.modalToggle}>
          <Modal.Header>請輸入分類資料</Modal.Header>
          <Modal.Content>
            <Segment basic loading={isLoading}>
              <Form>
                <Form.Group className='form-group-margin' grouped>
                  <Form.Input required value={displayName} data-input-type='text' data-state-name='displayName' onChange={this.inputChange} label='名稱' placeholder='名稱' />
                  {this.validator.message('displayName', displayName, 'required')}
                </Form.Group>
                <Form.Group grouped>
                  <Form.Select value={superCategoryId} data-input-type='select' data-state-name='superCategoryId' options={options} onChange={this.inputChange} label='分類類別' placeholder='分類類別' />
                  <Form.Input type='number' required value={order||''} data-input-type='text' data-state-name='order' onChange={this.inputChange} label='順序' placeholder='順序' />
                </Form.Group>
              </Form>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' content='取消' icon='cancel' data-modalname='isEditModalOpen' onClick={this.modalToggle} circular disabled={isLoading} />
            <Button color='green' content='儲存' icon='save' onClick={this.save} circular disabled={isLoading} />
          </Modal.Actions>
        </Modal>

        <ConfirmModal open={isDeleteModalOpen} description={<>確定刪除分類 <span className='red'>{displayName}</span>？本操作不可逆轉！</>} data-modalname='isDeleteModalOpen' cancel={this.modalToggle} confirm={this.delete} />
      </>
    )
  }
}

export default category;