/* import react */
import React from 'react';

/* import components */
import {EmptyTableMsg, _Component, ResponsiveTable} from '../../Components';

/* import helper functions */
import { _get, _post } from '../../Helper/ApiHelper';

/* import semantic-ui element */
import { Grid, Table, Checkbox } from 'semantic-ui-react';

class Permission extends _Component {
  constructor(props) {
    super(props);
    //please provide your translation here
    this.tableHeader = [
      { columnName: 'displayName', displayName: '名稱' },
      { columnName: 'manageTeachers', displayName: '管理老師' },
      { columnName: 'manageStudents', displayName: '管理學生' },
      { columnName: 'manageCategories', displayName: '管理分類' },
      { columnName: 'commentOnVideos', displayName: '發表評論' },
      { columnName: 'modifyOtherComments', displayName: '修改他人評論' },
      { columnName: 'modifyOtherVideos', displayName: '修改他人影片' },
      { columnName: 'approveVideos', displayName: '批核他人影片' },
    ];
    this.state = {
      positions: [],
    }
  }

  //get position info
  getPositions = (callback = () => { }) => {
    const { promises } = this;
    promises.getPositions = _get('getPosition/');
    promises.getPositions.promise.then((positions) => {
      this.setState(
        { positions },
        callback()
      );
    }).catch(_r => { });
  }

  componentDidMount = () => {
    this.getPositions();
  }

  toggle = (positionId, fieldName, nextValue) => () => {
    const { promises } = this;
    promises.setPosition = _post('editPosition/', { id: positionId, [fieldName]: nextValue ? 1 : null });
    promises.setPosition.promise.then((_res) => {
      this.getPositions();
    }).catch(_r => { });
  }

  render() {
    const {
      positions,
    } = this.state;

    const { tableHeader } = this;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ResponsiveTable textAlign='center' sortable selectable celled unstackable columnInfo={tableHeader.map(x => x.displayName).concat('行動')}>
              <Table.Header>
                <Table.Row >
                  {tableHeader.map(({ displayName, columnName }) => {
                    return (
                      <Table.HeaderCell key={columnName}>
                        {displayName}
                      </Table.HeaderCell>
                    )
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {positions.length > 0 ? (
                  <>
                    {positions.map(position => (
                      <Table.Row key={position.id}>
                        {tableHeader.map(({ columnName }) => (
                          <Table.Cell
                            key={[position.id, columnName, position[columnName]]}
                            className='cursor'
                            onClick={this.toggle(position.id, columnName, !position[columnName])}>
                            {columnName === 'displayName' ? position[columnName] : <Checkbox checked={!!position[columnName]} />}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </>
                ) : <EmptyTableMsg colSpan={tableHeader.length} />
                }
              </Table.Body>
            </ResponsiveTable>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default Permission;