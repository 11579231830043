import * as React from "react";
export const UserContext = React.createContext(
  // default values used by a Consumer when it does not have a
  // matching Provider above it in the tree, useful for testing
  {
    userContext: {
      loggedInUser: {
        id: null,
        email: '',
        name: '',
        classCode: '',
        classNo: '',
        desc: '',
        enabled: null,
        imgUrl: 'blank.png',
        lastLogin: null,
        position_id: null,
        sessionId: null,
      },
      getPermission: () => { },
      setUser: () => { },
      resetUser: () => { },
      pingUser: () => { },
      userValid: null
    }
  }
);
