import React, { Fragment } from 'react';
import find from 'lodash/find';

import { inputHandler } from '../Helper/FormHelper';
import { withUser } from '../Context/withUser';
import { _Component } from '../Components';

/* import semantic-ui element */
import {
  Header,
  Grid,
  Icon,
  Segment,
  Responsive,
  Form
} from 'semantic-ui-react'

import Avatar from './Avatar';
import Buttons from './Buttons'

class Comments extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
      content: '',
      isEditing: false,
    }
  }

  inputComment = (event, data) => {
    this.setState({ content: inputHandler('text', data) })
  }

  undoEditComment = () => {
    return this._setState({
      selectedId: null,
      content: '',
      isEditing: false,
    })
  }

  startEditComment = (event) => {
    const {commentId} = event.target.closest('.ui').dataset;
    let content = '';

    if (commentId) {
      const findObject = find(this.props.comments, { id: +commentId });
      if (findObject) {
        content = findObject.content
      }
    }

    this.setState({
      selectedId: +commentId,
      content,
      isEditing: true,
    })
  }

  saveComment = async (event) => {
    try{
      const {commentId} = event.target.closest('.ui').dataset;
      await this.props.edit(commentId, this.state.content);
      await this.undoEditComment();
    }catch(err){
      console.log("ERR when saving comment", err);
    }
  }

  render() {
    const {
      comments,
      userContext: { loggedInUser: { id }, getPermission },
      creator_id,
    } = this.props;

    const {
      selectedId,
      isEditing,
      content
    } = this.state;

    return (<Segment attached='bottom'>
      {comments.length ? (
        <Grid textAlign='left'>
          {comments.map(comment => {
            const canEditComment = (id === comment.user_id) || getPermission('modifyOtherComments');
            const canDeleteComment = canEditComment||(id===creator_id);

            return (<Grid.Row key={comment.id}>
              <Responsive as={Fragment} minWidth={641}>
                <Grid.Column width='3' textAlign="center">
                  <Avatar {...comment} size="mini" />
                </Grid.Column>
                <Grid.Column width='13' verticalAlign='middle'>
                  {selectedId !== comment.id || !isEditing ? <p className="desc-text">{comment.content}</p> : (
                    <>
                      <Form>
                        <Form.TextArea value={content||''} onChange={this.inputComment} />
                        <Buttons.CancelButton
                          className="comment-buttons"
                          floated="right"
                          onClick={this.undoEditComment}
                          />
                        <Buttons.SaveButton
                          className="comment-buttons"
                          floated="right"
                          data-comment-id={comment.id}
                          onClick={this.saveComment}
                        />
                      </Form>
                    </>
                  )}
                  {canDeleteComment && !(isEditing && selectedId === comment.id) && (
                    <Buttons.DeleteButton
                      className="comment-buttons"
                      floated="right"
                      data-comment-id={comment.id}
                      onClick={this.props.deleteModalToggle}
                    />
                  )}
                  {canEditComment && !(isEditing && selectedId === comment.id) && (
                    <Buttons.EditButton
                      className="comment-buttons"
                      floated="right"
                      data-comment-id={comment.id}
                      onClick={this.startEditComment}
                    />
                  )}
                </Grid.Column>
              </Responsive>
              <Responsive as={Fragment} maxWidth={640}>
                <Grid.Column width='6' textAlign="center">
                  <Avatar {...comment} size="mini" />
                </Grid.Column>
                <Grid.Column width='10'>
                  {selectedId !== comment.id || !isEditing ? <p className="desc-text">{comment.content}</p> : (
                    <>
                      <Form>
                        <Form.TextArea value={content} onChange={this.inputComment} />
                        <Buttons.CancelButton
                          className="comment-buttons"
                          floated="right"
                          size="tiny"
                          onClick={this.undoEditComment}
                        />
                        <Buttons.SaveButton
                          className="comment-buttons"
                          floated="right"
                          size="tiny"
                          data-comment-id={comment.id}
                          onClick={this.saveComment}
                        />
                      </Form>
                    </>
                  )}
                  {canDeleteComment && !(isEditing && selectedId === comment.id) && (
                    <Buttons.DeleteButton
                      size="tiny"
                      className="comment-buttons"
                      floated="right"
                      data-comment-id={comment.id}
                      onClick={this.props.deleteModalToggle}
                    />)}
                  {canEditComment && !(isEditing && selectedId === comment.id) && (
                    <Buttons.EditButton
                      size="tiny"
                      className="comment-buttons"
                      floated="right"
                      data-comment-id={comment.id}
                      onClick={this.startEditComment}
                    />)}
                </Grid.Column>
              </Responsive>
            </Grid.Row>)
          })}
        </Grid>) : (
          <Grid textAlign='center'>
            <Header as='h3' icon>
              <Icon name='warning' />
              未有評論
            </Header>
          </Grid>)}
    </Segment>)
  }
}

export default withUser(Comments);