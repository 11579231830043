/* import react */
import React from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {EmptyTableMsg, _Component, ResponsiveTable} from '../../Components';

/* import helper functions */
import { get } from '../../Helper/ApiHelper';
import { tableSorting } from '../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Table } from 'semantic-ui-react';

const tableHeader = [
  { displayName: '作者', columnName: 'creator' },
  { displayName: '班別', columnName: 'classCode' },
  { displayName: '學號', columnName: 'classNo' },
  { displayName: '標題', columnName: 'title' },
  { displayName: '分類', columnName: 'category' },
];

class UnapprovedVideo extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      videoInfo: [],
      finishedLoading: false,

      sortColumn: null,
      sortDirection: null,
    }
  }
  
  //get student info
  getUnapprovedVideo = async () => {
    let videoInfo = [];
    try{
      videoInfo = await this.assignPromise(get('getUnapprovedVideo/'), 'getUnapprovedVideo');
    }catch(err){
      if (!this.isCancelled(err)) console.log("Error when fetching unapproved videos", err);
      videoInfo = [];
    }
    this._setState({
      videoInfo,
      finishedLoading: true
    })
  }

  componentDidMount = () => {
    this.getUnapprovedVideo();
  }

  /* table sorting */
  sorting = (clickedColumn) => () => {
    const { videoInfo, sortColumn, sortDirection } = this.state;
    const sortingResult = tableSorting(clickedColumn, videoInfo, sortColumn, sortDirection, {
      'classNo': true,
    });
    this.setState({
      sortColumn: clickedColumn,
      sortDirection: sortingResult.sortDirection,
      videoInfo: sortingResult.data
    });
  }

  toVideo = (event) => {
    const id = event.target.dataset.itemId;
    this.props.history.push(`/video/${id}`);
  }

  render() {
    const {
      videoInfo,
      finishedLoading,
      sortColumn,
      sortDirection,
    } = this.state;
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ResponsiveTable textAlign='center' sortable selectable celled unstackable columnInfo={tableHeader.map(x => x.displayName).concat('行動')}>
                <Table.Header>
                  <Table.Row>
                    {tableHeader.map(({ displayName, columnName }, index) => {
                      return (
                        <Table.HeaderCell key={index} sorted={sortColumn === columnName ? sortDirection : null} onClick={this.sorting(columnName)}>
                          {displayName}
                        </Table.HeaderCell>
                      )
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {videoInfo.length > 0 ?
                    <>
                      {videoInfo
                        .map(({ id, name, classCode, classNo, title, category }) => (
                          <Table.Row key={id}>
                            <Table.Cell
                              className='cursor'
                              data-item-id={id}
                              onClick={this.toVideo}
                              content={name}
                            />
                            <Table.Cell
                              className='cursor'
                              data-item-id={id}
                              onClick={this.toVideo}
                              content={classCode}
                            />
                            <Table.Cell
                              className='cursor'
                              data-item-id={id}
                              onClick={this.toVideo}
                              content={classNo}
                            />
                            <Table.Cell
                              className='cursor'
                              data-item-id={id}
                              onClick={this.toVideo}
                              content={title}
                            />
                            <Table.Cell
                              className='cursor'
                              data-item-id={id}
                              onClick={this.toVideo}
                              content={category || '未分類'}
                            />
                          </Table.Row>
                        ))}
                    </>
                    :
                    <EmptyTableMsg colSpan='5' msg={finishedLoading?"全部影片都已批閱":"載入資料中"} />
                  }
                </Table.Body>
              </ResponsiveTable>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}

export default withRouter(UnapprovedVideo);