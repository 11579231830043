import Videos from './Videos';
import Ratings from './Ratings';
import Comments from './Comments';
import Fav from './Fav';
// import Fans from './Fans';
// import Subs from './Subs';
// import ProfileSetting from './MyProfile';
import AddVideo from './AddVideo';

export default {
  Videos,
  Ratings,
  Comments,
  Fav,
  // Fans,
  // Subs,
  // ProfileSetting,
  AddVideo,
}