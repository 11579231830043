/* import lodash */
import find from 'lodash/find';
import pick from 'lodash/pick';

/* import react */
import React from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {ErrorLabel, ConfirmModal, FileInputInstantUpload, FullscreenDimmer, EmptyTableMsg, _Component, ResponsiveTable} from '../../Components';

import { excelFormats } from '../../Const';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import { tableSorting } from '../../Helper/Helper';
import { floatRight } from '../../Helper/StyleHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Checkbox, Input } from 'semantic-ui-react';

const tableHeader = [
  { displayName: '電郵', columnName: 'email' },
  { displayName: '姓名', columnName: 'name' },
  { displayName: '班別', columnName: 'classCode' },
  { displayName: '學號', columnName: 'classNo' },
];

class Student extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      studentInfo: null,

      finishedLoading: false,
      dimmerOpen: false,

      editInfo: {},
      isEditModalOpen: false,
      isInactivateModalOpen: false,
      isDeleteModalOpen: false,

      selectedId: null,
      email: '',
      classCode: '',
      classNo: '',
      name: '',

      sortColumn: null,
      sortDirection: null,

      showHidden: false,

      filterStr: '',
    }
    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        email: '請輸入有效電郵地址',
        required: '請輸入資料'
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  //get student info
  getStudent = async () => {
    try {
      const studentInfo = await get('getStudent/');
      await this.setStateAsync({ studentInfo });
    } catch (_r) { };
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.getStudent();
    this.setStateAsync({
      finishedLoading: true,
    });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value })
  }

  /* modal toggle */
  modalToggle = (modalStateName, selectedId = null) => () => {
    let editObject = {
      email: '',
      classCode: '',
      classNo: '',
      name: '',
    };

    if (selectedId !== null) {
      const { studentInfo } = this.state;
      editObject = find(studentInfo, { id: selectedId });
    }

    this.setStateAsync({
      ...pick(editObject, ['email', 'classCode', 'classNo', 'name']),
      selectedId,
      [modalStateName]: !this.state[modalStateName]
    });
  }

  /* save student */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    let data = Object.assign(pick(this.state, ['email', 'classCode', 'classNo', 'name']), {
      enabled: 1, //default shown
    });

    if (this.state.selectedId) {
      data.id = this.state.selectedId;
    }
    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editUser/', data, 'FORMDATA');
      if (result && result.status) {
        await this.getStudent();
        this.modalToggle('isEditModalOpen')();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== "unmounted") {
        alert(`無法儲存學生${typeof err === 'string' ? '：' + err : ''}`);
        console.log("Error when saving student", err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false
    });
  }

  activate = async () => {
    let data = { id: this.state.selectedId, enabled: 1 };
    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editUser/', data, 'FORMDATA');
      if (result && result.status) {
        await this.getStudent();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== 'unmounted') {
        alert('將學生設為在校時發生錯誤');
        console.log("Error when activating student", err);
      }
    }
    this.modalToggle('isActivateModalOpen')();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false
    });
  }

  inactivate = async () => {
    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editUser/', { id: this.state.selectedId, enabled: null }, 'FORMDATA');
      if (result && result.status) {
        await this.getStudent();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== 'unmounted') {
        alert('將學生設為退校時發生錯誤');
        console.log("Error when inactivating student", err);
      }
    }
    this.modalToggle('isInactivateModalOpen')();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false
    });
  }

  /* delete student */
  delete = async () => {
    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('deleteUser', { id: this.state.selectedId });
      if (result && result.status) {
        await this.getStudent();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== 'unmounted') {
        alert('刪除學生時發生錯誤');
        console.log("Error when deleting student", err);
      }
    }
    this.modalToggle('isDeleteModalOpen')();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false
    });
  }

  /* table sorting */
  sorting = (clickedColumn) => () => {
    const { studentInfo, sortColumn, sortDirection } = this.state;
    const sortingResult = tableSorting(clickedColumn, studentInfo, sortColumn, sortDirection, {
      'classNo': true,
    });
    this.setStateAsync({
      sortColumn: clickedColumn,
      sortDirection: sortingResult.sortDirection,
      studentInfo: sortingResult.data
    });
  }

  toUserPage = (event) => this.props.history.push(`/user/${event.target.closest('td').dataset.userId}`)

  marginLeft1rem = { 'marginLeft': '1rem' }

  fileHandling = async (xlsFile) => {
    //file parse
    const reader = new FileReader();
    try {
      const XLSX = await import('xlsx');
      const result = await new Promise((res, rej) => {
        reader.onload = (evt) => { //evt = on_file_select event
          /* Parse data */
          const binaryString = evt.target.result;
          const workBook = XLSX.read(binaryString, { type: 'binary' });
          /* Get first worksheet, object {[cellAddress]: value} */
          const wsname = workBook.SheetNames[0];
          const data = workBook.Sheets[wsname];
          this.handleXLSX(data).then(res).catch(err => { rej(err); });
        };
        reader.onerror = (evt) => {
          console.log('err', evt);
          rej('讀取檔案時發生錯誤');
        }
        reader.readAsBinaryString(xlsFile);
      });
      return result;
    } catch (err) {
      return { status: false, err }
    }
  }

  handleXLSX = async (data) => {
    const colMap = {
      name: 'A',
      email: 'B',
      classCode: 'C',
      classNo: 'D',
    };
    const rowCount = +data['!ref'].match(/\d+$/g);
    const result = {
      data: [],
    };
    //1 is for title
    const emailSet = new Set();
    for (var i = 2; i <= rowCount; i++) {
      const resultRow = {};
      if (!data['A' + i]) {
        break;
      }
      if (!data['B' + i]) {
        throw `列#${i}電郵無效`;
      }
      if (emailSet.has(data['B' + i].w)) {
        throw `列#${i}電郵重複`;
      }
      emailSet.add(data['B' + i].w);

      for (let field in colMap) {
        resultRow[field] = data[colMap[field] + i].w;
      }
      resultRow.enabled = 1;
      result.data.push(resultRow);
    }
    try {
      const response = await post('batchEditStudent/', result);
      if (response && response.status) {
        this.getStudent();
      }
      return response;
    } catch (err) {
      console.log('Err caught finally', err);
    }
  }

  render() {
    const {
      studentInfo,
      isEditModalOpen,
      isActivateModalOpen,
      isInactivateModalOpen,
      isDeleteModalOpen,
      email,
      classCode,
      classNo,
      name,
      sortColumn,
      sortDirection,
      showHidden,
      filterStr,
      finishedLoading,
      dimmerOpen
    } = this.state;

    let trueStudentInfo = null;
    if (studentInfo) {
      trueStudentInfo = studentInfo.filter(x => (showHidden || x.enabled) && (!filterStr || `${x.email}\r${x.name}\r${x.classCode}${x.classNo}`.indexOf(filterStr) > -1));
    }
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Checkbox
                label='顯示已停用學生'
                checked={showHidden}
                onChange={this.inputChange('checkbox', 'showHidden')}
                disabled={!finishedLoading}
              />
              <Input
                value={filterStr}
                onChange={this.inputChange('text', 'filterStr')}
                placeholder='篩選學生' style={this.marginLeft1rem}
                disabled={!finishedLoading}
              />

              <Button
                as='a'
                href='/download/student_sample.xlsx'
                download
                color='orange'
                content='下載學生檔案範本'
                icon='download'
                floated='right'
                circular
              />
              <FileInputInstantUpload
                disabled={!finishedLoading}
                buttonColor='green'
                buttonText='上載學生檔案'
                formatErrorText='請上載正確格式(xlsx)'
                accept={excelFormats}
                wrapperStyle={floatRight}
                fileHandling={this.fileHandling}
              />
              <Button
                color='orange'
                content='新增學生'
                icon='add'
                floated='right'
                onClick={this.modalToggle('isEditModalOpen')}
                circular
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ResponsiveTable textAlign='center' sortable selectable celled unstackable columnInfo={tableHeader.map(x => x.displayName).concat('行動')}>
                <Table.Header>
                  <Table.Row >
                    {tableHeader.map(({ displayName, columnName }, index) => {
                      return (
                        <Table.HeaderCell key={index} sorted={sortColumn === columnName ? sortDirection : null} onClick={this.sorting(columnName)}>
                          {displayName}
                        </Table.HeaderCell>
                      )
                    })}
                    <Table.HeaderCell>行動</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {trueStudentInfo ? trueStudentInfo.length > 0 ?
                    <>
                      {trueStudentInfo
                        .map(({ id, email, name, classCode, classNo, enabled }) => (
                          <Table.Row className={enabled ? '' : ' left-school'} key={email}>
                            <Table.Cell
                              className='cursor'
                              data-user-id={id}
                              onClick={this.toUserPage}>{email}</Table.Cell>
                            <Table.Cell
                              className='cursor'
                              data-user-id={id}
                              onClick={this.toUserPage}>{name}</Table.Cell>
                            <Table.Cell
                              className='cursor'
                              data-user-id={id}
                              onClick={this.toUserPage}>{classCode}</Table.Cell>
                            <Table.Cell
                              className='cursor'
                              data-user-id={id}
                              onClick={this.toUserPage}>{classNo}</Table.Cell>
                            <Table.Cell>
                              {enabled ? (
                                <>
                                  <Button color='blue' icon='edit' onClick={this.modalToggle('isEditModalOpen', id)} circular />
                                  <Button color='orange' icon='eye slash' onClick={this.modalToggle('isInactivateModalOpen', id)} circular />
                                </>
                              )
                                : (
                                  <>
                                    <Button color='blue' icon='edit' disabled circular />
                                    <Button color='green' icon='eye' onClick={this.modalToggle('isActivateModalOpen', id)} circular />
                                  </>
                                )}
                              <Button color='red' icon='delete' onClick={this.modalToggle('isDeleteModalOpen', id)} circular />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </> : <EmptyTableMsg colSpan='5' /> : <EmptyTableMsg colSpan='5' msg='資料載入中' />
                  }
                </Table.Body>
              </ResponsiveTable>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? (<FullscreenDimmer active={dimmerOpen} isLoading={true} />) : <>
          <Modal open={isEditModalOpen} closeOnDimmerClick={false} onClose={this.modalToggle('isEditModalOpen')}>
            <Modal.Header>請輸入學生資料</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  <Form.Group className='form-group-margin' grouped>
                    <Form.Input value={email} onChange={this.inputChange('text', 'email')} label='電郵' placeholder='電郵' />
                    {this.validator.message('email', email, 'required|email')}
                  </Form.Group>
                  <Form.Group className='form-group-margin' grouped>
                    <Form.Input value={name} onChange={this.inputChange('text', 'name')} label='姓名' placeholder='姓名' />
                    {this.validator.message('name', name, 'required')}
                  </Form.Group>
                  <Form.Group className='form-group-margin' grouped>
                    <Form.Input value={classCode} onChange={this.inputChange('text', 'classCode')} label='班別' placeholder='班別' />
                  </Form.Group>
                  <Form.Group className='form-group-margin' grouped>
                    <Form.Input value={classNo} onChange={this.inputChange('text', 'classNo')} label='學號' placeholder='學號' />
                  </Form.Group>
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
              <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
            </Modal.Actions>
          </Modal>

          <ConfirmModal open={isActivateModalOpen} description='確定將學生轉回為「非退校」並預設顯示？' cancel={this.modalToggle('isActivateModalOpen')} confirm={this.activate} confirmText='取消退校' confirmIcon='eye' />
          <ConfirmModal open={isInactivateModalOpen} description='確定將學生轉為「已退校」並預設隱藏？' cancel={this.modalToggle('isInactivateModalOpen')} confirm={this.inactivate} confirmText='退校' confirmIcon='eye slash' />
          <ConfirmModal open={isDeleteModalOpen} description='確定刪除學生？本操作不可逆轉！' cancel={this.modalToggle('isDeleteModalOpen')} confirm={this.delete} />
        </>
        }
      </>
    )
  }
}

export default withRouter(Student);