import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import pick from 'lodash/pick';

/* import semantic-ui element */
import {
  Grid,
  Header,
  Responsive,
} from 'semantic-ui-react';

import BriefVideo from './BriefVideo';
import RelatedBriefVideo from './RelatedBriefVideo';

const vidFields = ['id', 'sourceId', 'title', 'time', 'desc', 'views', 'creator_id', 'info'];

const VideoSegment = (props) => {
  const {
    title,
    titleLink,
    showAvatar,
  } = props;

  let { videoList } = props;
  videoList = videoList.filter(x => x.status);
  if (props.limit) videoList = videoList.slice(0, props.limit);

  if (!videoList.length) {
    return <div className="video-segment"></div>
  }

  return (
    <div className="video-segment">
      {titleLink ? <Link to={titleLink}><Header as="h1">{title}</Header></Link> : <Header as="h1">{title}</Header>}
      <Responsive as={Fragment} minWidth={481}>
        <Grid columns={1} padded='vertically' doubling>
          {videoList.map(x => {
            const vidProps = { showAvatar }
            if (typeof (x) === "object") {
              Object.assign(vidProps, pick(x ,vidFields));
            } else {
              vidProps.id = x;
            }
            return (
              <Grid.Row key={vidProps.id} stretched>
                <Grid.Column><RelatedBriefVideo {...vidProps} /></Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </Responsive>
      <Responsive as={Fragment} maxWidth={480}>
        {videoList.map(x => {
          const vidProps = { showAvatar }
          if (typeof (x) === "object") {
            Object.assign(vidProps, pick(x ,vidFields));
          } else {
            vidProps.id = x;
          }
          return (<BriefVideo key={vidProps.id} {...vidProps} />);
        })}
      </Responsive>
    </div>
  );
}

export default VideoSegment;