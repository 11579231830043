import Category from './Category';
import Featured from './Featured';
import Permission from './Permission';
import Student from './Student';
import Teacher from './Teacher';
import Video from './Video';

export default {
  Category,
  Featured,
  Permission,
  Student,
  Teacher,
  Video,
}