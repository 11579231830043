import { Component } from 'react';
import { makeCancelable } from '../Helper/ApiHelper';

class _Component extends Component {
  constructor(props) {
    super(props);
    this.promises = {};
  }

  getCurrentUserId = () => {
    return this.props.userContext.loggedInUser.id;
  }

  componentWillUnmount = () => {
    Object.keys(this.promises).forEach(x => {
      if(this.promises.hasOwnProperty(x))
        this.promises[x].cancel();
    })
  }

  /**
   * Assign promise to this.promises and return the promise
   * @param {Promise} promise 
   * @param {string} handleName
   * @returns {Promise} the cancellable promise
   */
  assignPromise = (promise, handleName) => {
    this.promises[handleName] = makeCancelable(promise);
    return this.promises[handleName].promise;
  }

  /**
   * Quick check for whether a promise is cancelled due to unmounting
   * @param {any} err error thrown
   * @returns {boolean}
   */
  isCancelled = (err) => {
    return err && typeof err === 'object' && err.isCanceled;
  }

  /**
   * Set state that's safe from memory leak
   * @param {object} data
   * @returns {Promise} the cancellable promise
   */
  _setState = (data) => {
    this.promises.setState = makeCancelable(Promise.resolve());
    return this.promises.setState.promise.then(()=>new Promise((res, rej)=>{
        this.setState(data, res)
      })
    );
  }
}
export default _Component;