/* import react */
import React from 'react';
import { withRouter } from 'react-router-dom';

/* import module nav */
import { _Component, Nav } from "../Components";
import { withUser } from '../Context/withUser';

/* import semantic-ui element */
import {
  Grid,
  Container,
} from 'semantic-ui-react';

class ModuleLayout extends _Component {
  constructor(props) {
    props.userContext.pingUser();
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.userContext.pingUser();
    }
  }

  render() {
    return (
      <>
        <Nav logout={this.props.logout} />
        <Grid celled='internally' className='module-main' id='main'>
          <Container>
            {this.props.content}
          </Container>
        </Grid>
      </>
    )
  }
}

export default withRouter(withUser(ModuleLayout));