import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import RouterConfig from './RouterConfig';

import { withUser } from '../Context/withUser';
import { post } from '../Helper/ApiHelper';
import { _Component } from '../Components';
// import {EmptySegmentMsg} from '../Components';

class Router extends _Component {
  constructor(props) {
    props.userContext.pingUser();
    super(props);
    this.state = {
      user: {
        id: null,
      },
    }
  }

  //FML
  saveProfile = (desc, newImage) => () => {
    const loggedInUser = { ...this.props.userContext.loggedInUser };
    const { id } = loggedInUser;

    this.assignPromise(post('editUser', {
      id,
      desc,
      newImage
    }, 'FORMDATA'), 'saveProfile').then((_res) => {
      loggedInUser.desc = desc
      if (_res.newImgUrl)
        loggedInUser.imgUrl = _res.newImgUrl;
      this.props.userContext.setUser(loggedInUser);
    }).catch(console.log);
  }

  render() {
    const { userValid } = this.props.userContext;
    if (userValid === null) {
      return false;
    }
    return (
      //<React.Suspense fallback={<EmptySegmentMsg loading={true}/>}>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route path='/' exact={true} component={RedirectToHomePage} />
            {RouterConfig.map(({ Path, Exact, Layout, Content }, index) => (
              <Route key={index} path={Path} exact={Exact} render={() => (
                <Layout
                  content={Content && <Content />}
                  logout={this.logout}
                  saveProfile={Path === '/myProfile' ? this.saveProfile : null}
                />
              )} />
            ))}
            <Route component={RedirectToHomePage} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
      //</React.Suspense>
    );
  }
}

function RedirectToHomePage(props) {
  return <Redirect to="/recommended" />
}

export default withUser(Router);