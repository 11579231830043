const localhost = process.env.NODE_ENV === 'development' ? 'http://localhost:'+process.env.REACT_APP_BACKEND_PORT : '';
/* get method */
export const get = (url) => {
  return fetch(localhost + '/' + url, {
    method: 'GET',
    credentials: 'include'
  }).then((response) => {
    return response.json();
  }).then((response) => {
    if (response.status) {
      return response.result;  //an object evaluates to True anyways
    }
    return [];
  })
}

/* post method */
export const post = (url, data = {}, type = 'JSON') => {
  let postPromise = null;
  switch (type) {
    case 'JSON':
      postPromise = fetch(localhost + '/' + url, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      });
      break;
    case 'FORMDATA':
      let formData = new FormData();
      Object.keys(data).forEach(x => {
        formData.append(x, data[x])
      });
      postPromise = fetch(localhost + '/' + url, {
        method: 'POST',
        credentials: 'include',
        body: formData
      });
      break;
    default:
      return false;
  }

  return postPromise.then((response) => {
    return response.json();
  }).then((response) => {
    if (response.status) {
      return response; //an object evaluates to True anyways
    }
    return false;
  })
}

/**
 * Make a promise cancellable.
 * @param {Promise} promise 
 * @returns {{promise: Promise, cancel()=>void}} The cancellable Promise
 */
export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
      error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};


/* get method */
export const _get = (url, params = []) => makeCancelable(
  new Promise((resolve, reject) => {
    return fetch(localhost + '/' + url, {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if (response.status) {
        resolve(response.result); //an object evaluates to True anyways
      } else {
        resolve([]);
      }
    }).catch((err) => {
      reject(false);
    })
  })
)

export const _post = (url, data = {}, type = 'JSON') => makeCancelable(
  new Promise((resolve, reject) => {
    switch (type) {
      case 'JSON':
        return fetch(localhost + '/' + url, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }).then((response) => {
          return response.json();
        }).then(resolve).catch((err) => {
          reject(false);
        });
      case 'FORMDATA':
        let formData = new FormData();
        Object.keys(data).forEach(x => {
          formData.append(x, data[x])
        });
        return fetch(localhost + '/' + url, {
          method: 'POST',
          credentials: 'include',
          body: formData
        }).then((response) => {
          return response.json();
        }).then(resolve).catch((err) => {
          reject(false);
        });
      default:
        reject(false);
        return;
    }
  })
);

/* post method */
export const postWithError = (url, data = {}, type = 'JSON') => {
  let postPromise = null;
  switch (type) {
    case 'JSON':
      postPromise = fetch(localhost + '/' + url, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      });
      break;
    case 'FORMDATA':
      let formData = new FormData();
      Object.keys(data).forEach(x => {
        formData.append(x, data[x])
      });
      postPromise = fetch(localhost + '/' + url, {
        method: 'POST',
        credentials: 'include',
        body: formData
      });
      break;
    default:
      return Promise.resolve({ status: false, err: '方法無效' });
  }

  return postPromise.then((response) => {
    return response.json();
  }).catch(_err => {
    return Promise.resolve({ status: false, err: '方法無效' });
  });
}

export const _all = (ar) => makeCancelable(Promise.all(ar));