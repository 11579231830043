/*
import {lazy} from 'react';
const Module = lazy(() => import('./ModuleLayout'))
//const Plain = lazy(() => import('./PlainLayout'))
const System = lazy(() => import('./SystemPanelLayout'))
const User = lazy(() => import('./UserPanelLayout'))
const Video = lazy(() => import('./VideoLayout'))
/*/
import Module from './ModuleLayout'
//import Plain from './PlainLayout'
import System from './SystemPanelLayout'
import User from './UserPanelLayout'
import Video from './VideoLayout'
//*/


export default {
  Module,
  //Plain,
  System,
  User,
  Video,
}