import React, { useState, useEffect, useCallback, useMemo, memo} from 'react';
import { Table } from 'semantic-ui-react';
import EmptyTableMsg from './EmptyTableMsg';
import { MyTableRow } from './MyTable';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';

const MySortableTable = (props) => {
  const [sortStack, setSort] = useState(props.sortStack||[]);
  const [data, setData] = useState(props.data);
  const totalCols = useMemo(()=>{
    return props.tableColumnData.reduce((prev,cur)=>prev+(cur.show!==false?(cur.colSpan?cur.colSpan:1):0),0);
  }, [props.tableColumnData]);

  const switchSorting = useCallback((event)=>{
    const {columnName} = event.target.dataset;
    if(sortStack.length && sortStack[0].column === columnName){
      setSort(sortStack=>{
        return [
          {
            ...sortStack[0],
            direction: sortStack[0].direction === 'ascending'?'descending':'ascending',          
          }
        ].concat(sortStack.slice(1))
      })
    }else{
      setSort(sortStack=>{
        const s = sortStack.slice();
        let index = findIndex(sortStack, {column: columnName});
        
        if(index>-1){
          s.splice(index,1);
        }
        return [{column: columnName, direction: 'ascending'}].concat(s)
      })
    }
  }, [sortStack]);
  
  useEffect(()=>{
    const sortLookup = {ascending: "asc", descending: "desc"};

    const arg2 = sortStack.map(x=>{
      const y = find(props.tableColumnData, {headerName: x.column});
      if(!y) return null;
      return y.sortMethod||y.cellRender;
    });

    const arg3 = sortStack.map(x=>sortLookup[x.direction]);

    setData(Array.isArray(props.data)?orderBy(props.data, arg2, arg3):props.data);
  }, [props.data, props.tableColumnData, sortStack]);

  let bodyContent = <EmptyTableMsg colSpan={totalCols} msg={props.loadingMsg || "載入資料中"} />;
  if(Array.isArray(data) && props.finishedLoading !== false){
    let trueData = data;
    if(props.genRowClassName){
      trueData = data.filter(item=>props.genRowClassName(item)!=='hidden-row')
    }
    if(trueData.length){
      bodyContent = trueData.map((item, i) => (
        <MyTableRow
          data={item}
          rowClassName={props.genRowClassName ? props.genRowClassName(item) : ''}
          tableColumnData={props.tableColumnData}
          key={props.keyHandle?item[props.keyHandle]:item.id}
          index={i}
        />
      ))
    }else{
      bodyContent = <EmptyTableMsg colSpan={totalCols} msg={props.noDataMsg || "找不到資料"} />;
    }
  }

  return (
    <Table fixed textAlign="center" selectable celled sortable unstackable {...(props.tableProps||{})}>
      <Table.Header>
        <Table.Row>
          {props.tableColumnData.filter(x=>x.show!==false).map(colData => colData.HeaderRender?<colData.HeaderRender key={colData.headerName} width={colData.width}/>:(
            <Table.HeaderCell
              key={colData.headerName}
              width={colData.width}
              sorted={sortStack.length && sortStack[0].column === colData.headerName ? sortStack[0].direction : null}
              data-column-name={colData.headerName}
              onClick={colData.allowSort ? switchSorting : undefined}
            >
              {colData.headerName}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>{bodyContent}</Table.Body>
      {props.tableFooterRender && <Table.Footer fullWidth><Table.Row><Table.HeaderCell colSpan={totalCols}>{props.tableFooterRender(data)}</Table.HeaderCell></Table.Row></Table.Footer>}
    </Table>
  )
}

export default memo(MySortableTable);