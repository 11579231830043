import React from 'react';

import {_Component, UserVideo, EmptySegmentMsg} from '../../Components';
import { get } from '../../Helper/ApiHelper';

class UserPanel_Video extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: null,
    };
  }

  componentDidMount() {
    this.assignPromise(get('getVideo/user/' + this.props.userId), 'getUserVideo').then(res => {
      return this._setState({
        videos: res
      })
    }).catch(_r => { });
  }

  render() {

    const {
      videos,
    } = this.state;

    if(!videos) return <EmptySegmentMsg loading={true}/>
    if(!videos.length) return <EmptySegmentMsg msg="此用戶未有上載影片"/>

    return videos.map(x => (
      <UserVideo
        key={x.id}
        video={x}
      />
    ))
  }
}

export default UserPanel_Video;