/* import react */
import React, { Component } from 'react';
import Setting from '../Modules/Setting';

/* import semantic-ui element */
import {
  Container,
  Grid,
  Menu,
} from 'semantic-ui-react';

import { Route, Switch, Redirect, Link, withRouter } from 'react-router-dom';

import { withUser } from '../Context/withUser';

const menu = [
  { displayName: '批閱影片', path: '/systemPanel/video',      Component: Setting.Video,      permission: 'approveVideos' },
  { displayName: '權限管理', path: '/systemPanel/permission', Component: Setting.Permission, permission: 'managePermissions' },
  { displayName: '老師管理', path: '/systemPanel/teacher',    Component: Setting.Teacher,    permission: 'manageTeachers' },
  { displayName: '學生管理', path: '/systemPanel/student',    Component: Setting.Student,    permission: 'manageStudents' },
  { displayName: '分類管理', path: '/systemPanel/category',   Component: Setting.Category,   permission: 'manageCategories' },
  // { displayName: '設定精選', path: '/systemPanel/featured',   Component: Setting.Featured,   permission: 'setFeaturedVideos' },
];

class SystemPanelLayout extends Component {
  constructor(props) {
    //alert('System Panel Layout Construct!');
    props.userContext.pingUser();
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      //alert('System Panel Layout Update!');
      this.props.userContext.pingUser();
    }
  }

  render() {
    const {
      location: { pathname },
      userContext: { getPermission },
    } = this.props;

    if (!getPermission('accessSystemPanel'))
      return <Redirect to='/recommended' />

    return (
      <>
        <Menu size='huge' fluid borderless>
          {!!menu.length && menu.map(({ displayName, path, permission }, index) => {
            return getPermission(permission) && (
              <Menu.Item key={index} name={displayName} active={pathname === path} as={Link} to={path} />
            )
          })}
          <Menu.Item key='toHome' name='回到首頁' position='right' as={Link} to='/recommended' />
        </Menu>
        <Grid celled='internally' className='user-main' id='main'>
          <Container>
            <Switch>
              {!!menu.length && menu.map(({ displayName, path, Component, permission }) => getPermission(permission) && (<Route
                key={displayName}
                path={path}
                render={() => <Component /> || <div />}
              />
              ))}
            </Switch>
          </Container>
        </Grid>
      </>
    )
  }
}

export default withRouter(withUser(SystemPanelLayout));