/* import react */
import React from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  _Component,
  VideoSegment,
  EmptySegmentMsg
} from '../../Components';

import { get } from '../../Helper/ApiHelper';

class VideoByInfoPanel extends _Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.match.params.title,
      type: props.match.params.type,
      checkedVideos: false,
      videos: [],
    };
  }
  getAllInfo = () => {
    if(['grade', 'difficulty'].indexOf(this.state.type)>=0){
      this.assignPromise(get(`getVideo/${this.state.type}/${this.state.title}`), 'getVideo').then(videos => {
        if (Array.isArray(videos)) {
          this.setState({
            checkedVideos: true,
            videos
          })
        }
      }).catch(_r => { });
    }else{
      this.setState({
        checkedVideos: true,
        videos: []
      })
    }
  };

  componentDidMount() {
    this.getAllInfo();
  }

  componentDidUpdate = () => {
    if(this.state.title !== this.props.match.params.title || this.state.type !== this.props.match.params.type){
      this.setState({
        title: this.props.match.params.title,
        type: this.props.match.params.type,
        checkedVideos: false,
      }, this.getAllInfo);
    }
  }

  render() {

    const {
      title,
      videos,
      checkedVideos
    } = this.state;
    
    if(!checkedVideos){
      return <div><EmptySegmentMsg
        msg="資料載入中"
        loading={true}
      /></div>;
    }

    if (this.props.match.params.title && checkedVideos && !videos.length) {
      return (<div>
        {<EmptySegmentMsg msg='此分類未有任何影片' />}
      </div>)
    }

    return (
      <VideoSegment
        title={title}
        videoList={videos}
        showAvatar={false}
      />
    )
  }
}

export default withRouter(VideoByInfoPanel);