/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import module nav */
import { Nav } from '../Components';
import { withUser } from '../Context/withUser';

/* import semantic-ui element */
import {
  Container,
  Grid
} from 'semantic-ui-react';

class VideoLayout extends Component {
  constructor(props) {
    //alert('Video Layout Construct!');
    props.userContext.pingUser();
    super(props);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      //alert('Video Layout Update!');
      this.props.userContext.pingUser();
    }
  }
  render() {
    return (
      <>
        <Nav logout={this.props.logout} />
        <Grid celled='internally' className='user-main' id='main'>
          <Container>
            <Grid className='content-padding'>
              {this.props.content}
            </Grid>
          </Container>
        </Grid>
      </>
    )
  }
}

export default withRouter(withUser(VideoLayout));