import * as React from "react";
import { SpinnerContext } from "./spinner-context";
export function withSpinner(Component) {
  return function ThemeComponent(props) {
    return (
      <SpinnerContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </SpinnerContext.Consumer>
    );
  };
}
