/* import react */
import React, { Fragment } from 'react';
import YouTube from 'react-youtube';
import _get from 'lodash/get';
import Linkify from 'react-linkify';

import { withRouter, Redirect } from 'react-router-dom';
import { withUser } from '../Context/withUser';

import { timeToDescription, now } from '../Helper/TimeHelper';
import { get, post } from '../Helper/ApiHelper';
import { inputHandler } from '../Helper/FormHelper';
import { grades, difficulties } from '../Const';

/* import components */
import Avatar from './Avatar';
// import Comments from './Comments';
import _Component from './_Component';
import ConfirmModal from './ConfirmModal'
import EmptySegmentMsg from './EmptySegmentMsg';
import KOLStarRatings from './KOLStarRatings';
// import NewComment from './NewComment';
import NewLabel from './NewLabel';

/* import semantic-ui element */
import {
  Header,
  Grid,
  Segment,
  // Accordion,
  Responsive,
  Icon,
  Label,
  Button
} from 'semantic-ui-react';

const toHour = (num) => Math.round((num||0)/30)/2;

const InfoFields = ({ info, componentDecorator }) => {
  return info && (<dl>
    <div className='desc-row'>
      <div className='desc-item'>
        <div className='desc-title'>學習目標：</div>
        <div className='video-info'>{info.target ? <ul>{info.target.split("\n").map((y, i) => <li key={i}><Linkify componentDecorator={componentDecorator}>{y}</Linkify></li>)}</ul> : "無"}</div>
      </div>
    </div>
    <div className='desc-row'>
      <div className='desc-item'>
        <div className='desc-title'>適合年級：</div>
        <div className='video-info'>{grades.filter((y, i) => _get(info, ['grade', i])).join("、")}</div>
      </div>
      <div className='desc-item'>
        <div className='desc-title'>難易程度：</div>
        <div className='video-info'>{difficulties.filter((y, i) => _get(info, ['difficulty', i])).join("、")}</div>
      </div>
      <div className='desc-item'>
        <div className='desc-title'>需時：</div>
        <div className='video-info'>{_get(info, ['min_minutes'])>=90?`${toHour(_get(info, ['min_minutes']))}-${toHour(_get(info, ['max_minutes']))}小時`:`${_get(info, ['min_minutes'])}-${_get(info, ['max_minutes'])}分鐘`}</div>
      </div>
    </div>
    <div className='desc-row'>
      <div className='desc-item'>
        <div className='desc-title'>硬件需求：</div>
        <div className='video-info'>{info.hard_req ? <ul>{info.hard_req.split("\n").map((y, i) => <li key={i}><Linkify componentDecorator={componentDecorator}>{y}</Linkify></li>)}</ul> : "無"}</div>
      </div>
    </div>
    <div className='desc-row'>
      <div className='desc-item'>
        <div className='desc-title'>軟件需求：</div>
        <div className='video-info'>{info.soft_req ? <ul>{info.soft_req.split("\n").map((y, i) => <li key={i}><Linkify componentDecorator={componentDecorator}>{y}</Linkify></li>)}</ul> : "無"}</div>
      </div>
    </div>
    <div className='desc-row'>
      <div className='desc-item'>
        <div className='desc-title'>前提：</div>
        <div className='video-info'>{info.pre_req ? <ul>{info.pre_req.split("\n").map((y, i) => <li key={i}><Linkify componentDecorator={componentDecorator}>{y}</Linkify></li>)}</ul> : "無"}</div>
      </div>
    </div>
    <div className='desc-row'>
      <div className='desc-item'>
        <div className='desc-title'>其他注意事項：</div>
        <div className='video-info'>{info.notice ? <ul>{info.notice.split("\n").map((y, i) => <li key={i}><Linkify componentDecorator={componentDecorator}>{y}</Linkify></li>)}</ul> : "無"}</div>
      </div>
    </div>
    {info.canOnline && <Label color='blue' size='large'>支援線上授課</Label>}
    {info.canTablet && <Label color='blue' size='large'>可使用平板上課</Label>}
    {info.tags && <div>{String(info.tags).split("\n").map((x,i)=><Label tag key={i} color='purple' size='large'>{x}</Label>)}</div>}
  </dl>)
}

class FullVideo extends _Component {
  constructor(props) {
    super(props);

    this.state = {
      hasVideo: null,
      status: false,
      id: props.videoId,
      sourceId: null,
      views: null,
      time: now(),
      title: '',
      desc: '',
      showDesc: false,
      contributors: [],
      comments: [],
      rating: null,
      curUserRating: null,
      creator: {},
      hasSentView: false,
      givingLike: false,
      isFav: false,

      newComment: '',
      isEditing: false,
      isDeleteModalOpen: false,
      commentId: null,
    }

    this.iframeProps = {
      width: "100%",
      height: "100%",
      playerVars: {
        autoplay: 0,
        modestbranding: 1,
        rel: 0,
      }
    };

    this.linkProps = {target: '_blank'};
  }

  getAllInfo = async () => {
    try {
      const { id } = this.state;
      let data = {};
      const videos = await this.assignPromise(get('getVideo/id/' + id), 'getVideo');
      if (Array.isArray(videos) && videos.length) {
        data = videos[0];
        data.hasVideo = true;
        window.document.title = `${data.title} — ${process.env.REACT_APP_NAME}`;
        const [contributors, userRating, creators, isFav] = await this.assignPromise(get('getFullVideoInfos/' + id), 'getFullVideoInfos');
        let stars = 0;
        if (Array.isArray(userRating) && userRating.length) {
          stars = userRating[0].stars;
        }

        const comments = await this.getComments();
        const rating = await this.getRating();

        this.setState({
          ...data,
          contributors,
          curUserRating: stars,
          creator: creators[0],
          comments,
          rating,
          isFav
        })
      } else {
        this.setState({
          hasVideo: false,
        })
      }

    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.getAllInfo();
  }

  componentDidUpdate(prevProps) {
    const newId = this.props.videoId;
    const oldId = prevProps.videoId;
    if (newId !== oldId) {
      this.setState({
        id: newId,
        newComment: '',
        isEditing: false,
        hasSentView: false,
      }, this.getAllInfo);
    }
  }

  toggleDesc = (e) => {
    this.setState({ showDesc: !this.state.showDesc })
  }

  deleteModalToggle = (event) => {
    if (!event) {
      this._setState({
        isDeleteModalOpen: false,
        commentId: null
      });
    } else {
      let commentId = null;
      try {
        commentId = event.target.closest('.ui.button').dataset.commentId || null;
      } catch (err) { commentId = null; }
      this._setState({
        isDeleteModalOpen: !this.state.isDeleteModalOpen,
        commentId
      })
    }
  }

  inputComment = (event, data) => {
    let value = inputHandler('text', data);
    this.setState({ newComment: value })
  }

  changeRating = (newRating) => {
    const f = async () => {
      try {
        await this.assignPromise(post('editRating', {
          video_id: this.state.id,
          stars: newRating,
        }), 'changeRating');

        const rating = await this.getRating();
        this.setState({
          curUserRating: newRating,
          rating
        })
      } catch (err) {

      }
    }
    f();
  }

  getComments = () => this.assignPromise(get('getComment/video/' + (this.state.id)), 'getComment')

  getRating = () => this.assignPromise(get(`getRating/video/${this.state.id}`), 'getRating')

  deleteComment = async () => {
    try {
      const result = await this.assignPromise(post('deleteComment', { id: this.state.commentId }));
      if (result && result.status) {
        const comments = await this.getComments();
        await this._setState({
          comments
        });
      } else {
        alert("無法刪除留言");
        console.log(result);
      }
    } catch (err) {
      if (!this.isCancelled(err)) console.log("Unable to delete comment", err);
    }
  }

  editComment = async (comment_id, content) => {
    try {
      const result = await this.assignPromise(post('editComment', { id: comment_id, content }));
      if (result && result.status) {
        const comments = await this.getComments();
        await this._setState({
          comments
        });
      } else {
        alert("無法編輯留言");
        console.log(result);
      }
    } catch (err) {
      console.log("Error when edit comment", err);
    }
  }

  addComment = async () => {
    try {
      const { id, newComment } = this.state;
      const addResult = await this.assignPromise(post('editComment', {
        video_id: id,
        user_id: this.getCurrentUserId(),
        content: newComment
      }), 'addComment');
      if (addResult && addResult.status) {
        const comments = await this.getComments();
        this.setState({
          comments,
          newComment: '',
          isEditing: false,
        })
      } else {
        alert("無法新增留言");
        console.log("Cannot add comment", addResult);
      }
    } catch (err) {

    }
  }

  startEditComment = () => {
    this.setState({
      isEditing: true,
    })
  }

  undoAddComment = () => {
    this.setState({
      newComment: '',
      isEditing: false,
    })
  }

  addView = () => {
    const { id, hasSentView, status } = this.state;
    if (!hasSentView && status) {
      this.assignPromise(post('addVideoView', { id }), 'addView').then(views => {
        this.setState({
          views: views.result || 0,
          hasSentView: true,
        })
      }).catch(_r => {
        if (!this.isCancelled(_r)){
          alert("無法更新觀看次數");
          console.log(_r);
        }
      });
    }
  }

  tryEnd = () => {
    if (!this.state.newComment) {
      this._setState({
        isEditing: false,
      })
    }
  }

  navigation = (eventOrRoute) => {
    if(typeof eventOrRoute === 'string'){
      this.props.history.push(eventOrRoute);
      return;
    }
    const {href} = eventOrRoute.target.closest('.item, .button');
    this.props.history.push('/'+href.split('/').slice(3).join('/'));
    eventOrRoute.preventDefault();
  }

  componentDecorator = (decoratedHref, decoratedText, key) => (
    <a target="_blank" rel="noreferrer noopener" href={decoratedHref} key={key}>
        {decoratedText}
    </a>
  )

  componentWillUnmount = () => {
    window.document.title = process.env.REACT_APP_NAME;
  }

  toggleLike = (evt) => {
    if(this.state.givingLike) return;
    this._setState({givingLike: true});
    this.assignPromise(post('toggleVideoLike', {
      video_id: this.state.id,
     }), 'toggleVideoLike').then(views => {
      this.setState({
        views: views.result || 0,
        isFav: !this.state.isFav,
        hasSentView: true,
        givingLike: false
      })
    }).catch(_r => {
      if(!this.isCancelled(_r)){
        this._setState({givingLike: false});
        alert("無法給 like");
        console.log(_r);
      }
    });
  }

  render() {
    const {
      id,
      sourceId,
      views,
      time,
      showDesc,
      title,
      desc,
      creator,
      hasVideo,
      status,
      rating,
      curUserRating,
      comments,
      newComment,
      isEditing,
      isFav,
      givingLike,
      info,
      isDeleteModalOpen
    } = this.state;

    const { loggedInUser, getPermission, userValid } = this.props.userContext;

    const modifyOtherVideos = getPermission('modifyOtherVideos');

    const contributors = this.state.contributors.slice(0, 5);

    if (hasVideo == null) {
      return <EmptySegmentMsg loading={true} />;
    }

    if (hasVideo === true && !status && !(getPermission('approveVideos'))) {
      return <Redirect to='/recommended' />
    }

    if (!hasVideo) {
      return <EmptySegmentMsg msg='影片不存在' />;
    }

    return (
      <div>
        <YouTube
          opts={this.iframeProps}
          videoId={sourceId}
          onPlay={this.addView}
          containerClassName='youtube-wrapper'
          className='youtube-player'
        />
        <Segment attached>
          <Grid textAlign='left'>
            <Grid.Row columns={2} className='full-video-info stackable'>
              <Grid.Column key='basic-info' width={12}>
                <span>(編號: #{id})</span>
                <Header as="h2" className="video-title">
                  {!!status && (
                    <>
                      <KOLStarRatings
                        rating={+rating}
                        starDimension='1.25rem'
                        starSpacing='0.125rem'
                      />
                      <br />
                    </>)}
                  {title}
                  {status ? <NewLabel time={time} /> : <>&nbsp;-&nbsp;<span className='red'>未批核</span></>}
                </Header>
                <Icon name='eye' />{+views || 0}<Icon name='calendar' className='left-padded' />{timeToDescription(time)}
              </Grid.Column>
              <Grid.Column className='contributors' key='contributors' width={4}>
                <Responsive as={Fragment} minWidth={641}>
                  <Grid columns={1}>
                    <Grid.Column textAlign='center'>
                      <Avatar user_id={creator.id} {...creator} size="tiny" headerSize="h4" />
                    </Grid.Column>
                    {contributors.map((contributor) => (
                      <Grid.Column textAlign='center' key={contributor.user_id}>
                        <Avatar {...contributor} size="tiny" headerSize="h4" />
                      </Grid.Column>
                    ))}
                  </Grid>
                </Responsive>
                <Responsive as={Fragment} maxWidth={640}>
                  <Grid columns={Math.min(contributors.length >= 4 ? 3 : 2, contributors.length + 1)}>
                    <Grid.Row>
                      <Grid.Column textAlign='center'>
                        <Avatar user_id={creator.id} {...creator} size="tiny" headerSize="h4" />
                      </Grid.Column>
                      {contributors.slice(0, contributors.length >= 4 || contributors.length === 2 ? 2 : 1).map((contributor) => (
                        <Grid.Column textAlign='center' key={contributor.user_id}>
                          <Avatar {...contributor} size="tiny" headerSize="h4" />
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                    {contributors.length >= 4 && (<Grid.Row>
                      {contributors.slice(contributors.length >= 4 ? 2 : 1).map((contributor) => (
                        <Grid.Column textAlign='center' key={contributor.user_id}>
                          <Avatar {...contributor} size="tiny" headerSize="h4" />
                        </Grid.Column>
                      ))}
                    </Grid.Row>)}
                  </Grid>
                </Responsive>
              </Grid.Column>
            </Grid.Row>
            {!!status && userValid && (
              <Grid.Row columns={2} className='full-video-info stackable'>
                <Grid.Column key='text' width={12}>
                  <div className='inline'>評分：<KOLStarRatings
                    rating={+curUserRating}
                    changeRating={this.changeRating}
                    name='rating'
                    starDimension='2rem'
                    starSpacing='0.1rem'
                  /></div>
                </Grid.Column>
                <Responsive as={React.Fragment} maxWidth={767}>
                  <Grid.Column key='heart' width={4}>
                      讚好：<Icon name='heart' id='fav-icon' size='large' color={isFav?'red':'grey'} className={givingLike?'light':'light cursor'} onClick={this.toggleLike}/>
                  </Grid.Column>
                </Responsive>
                <Responsive as={React.Fragment} minWidth={768}>
                  <Grid.Column key='heart' width={4} textAlign='right'>
                    <div className='fav-icon-container'> 
                      <Icon name='heart' id='fav-icon' size='large' color={isFav?'red':'grey'} className={givingLike?'light':'light cursor'} onClick={this.toggleLike}/>
                    </div>
                  </Grid.Column>
                </Responsive>
              </Grid.Row>
            )}
            <Grid.Row columns={1}>
              <Grid.Column className='full-video-info'>
                {(!!modifyOtherVideos || creator.id === loggedInUser.id) && <p><Button color="red" content="編輯影片" compact href={`/editvideo/${id}`} onClick={this.navigation} /></p>}
                <p className='desc-text'>
                  <Linkify componentDecorator={this.componentDecorator}>{desc}</Linkify>
                </p>
                <InfoFields
                  info={info}
                  componentDecorator={this.componentDecorator}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {/* {userValid && (<NewComment
          add={this.addComment}
          edit={this.inputComment}
          value={newComment}
          isEditing={isEditing}
          start={this.startEditComment}
          undo={this.undoAddComment}
          tryEnd={this.tryEnd}
        />)}
        <Comments
          comments={comments}
          edit={this.editComment}
          creator_id={creator.id}
          deleteModalToggle={this.deleteModalToggle}
        /> */}
        <ConfirmModal
          open={isDeleteModalOpen}
          description='您真的要刪除評論嗎？'
          data-modalname='isDeleteModalOpen'
          cancel={this.deleteModalToggle}
          confirm={this.deleteComment}
        />
      </div>
    );
  }
}

export default withRouter(withUser(FullVideo));