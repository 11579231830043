/* import react */
import React, { memo } from 'react';
import {Button} from 'semantic-ui-react';

const CancelButton = memo(props => <Button
  content={props.concise ? undefined : '取消'}
  color='red'
  icon='cancel'
  size="small"
  title={props.title ? props.title : "刪除"}
  type='button'
  {...props}
/>);

const SaveButton = memo(props => <Button
  content={props.concise ? undefined : '儲存'}
  color='green'
  icon='save'
  size="small"
  title={props.title ? props.title : "儲存"}
  type='button'
  {...props}
/>);

const EditButton = memo(props => <Button
  content={props.concise ? undefined : '編輯'}
  color='blue'
  icon='edit'
  size="small"
  title={props.title ? props.title : "編輯"}
  type='button'
  {...props}
/>);

const DeleteButton = memo(props => <Button
  content={props.concise ? undefined : '刪除'}
  color='red'
  icon='delete'
  size="small"
  title={props.title ? props.title : "刪除"}
  type='button'
  {...props}
/>);

const AddButton = memo(props => <Button
  color='green'
  icon='add'
  circular
  title={props.content ? props.content : "新增"}
  type='button'
  {...props}
/>);

export default {
  CancelButton,
  SaveButton,
  EditButton,
  DeleteButton,
  AddButton
}