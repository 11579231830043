/* import react */
import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

/* import semantic-ui element */
import {
  Form,
  Grid,
  Container,
  Header,
  Checkbox
} from 'semantic-ui-react';

import { get } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import { selectOptions } from '../../Helper/Helper';
import { grades, difficulties } from '../../Const';

import { _Component, MySelect, ConfirmModal, Buttons, EmptySegmentMsg, BlockerPrompt } from '../../Components';
import { withUser } from '../../Context/withUser';

class AddVideo extends _Component {

  constructor(props) {
    super(props);

    this.state = {
      isRedirect: false,
      isDeleteModalOpen: false,
      redirectTo: '/recommended',
      id: null,
      sourceId: '',
      title: '',
      desc: '',
      category_id: [],
      catOptions: [],
      info: {
        min_minutes: '',
        max_minutes: '',
        hard_req: '',
        soft_req: '',
        pre_req: '',
        notice: '',
        grade: grades.map(x=>0),
        difficulty: difficulties.map(x=>0),
        canOnline: false,
        canTablet: false,
      },
      finishedLoading: false,
      isBlocking: false,
      // user_info: [],
      // contributors: [],
    }

    this.reqs = [
      {fieldName: 'hard_req', displayName: '硬件需求'},
      {fieldName: 'soft_req', displayName: '軟件需求'},
      {fieldName: 'pre_req', displayName: '前提'},
      {fieldName: 'notice', displayName: '其他注意事項'},
    ]
  }

  componentDidMount = async () => {
    try{
      const {videoId} = this.props.match.params;
      const priv = await this.assignPromise(get('getPrivilege/' + videoId), 'getPriv');
      if(!priv){
        this.props.history.replace('/addVideo');
      }
      const cat = await this.assignPromise(get('getCategory'), 'getCategory');
      let vid = null, category_id = [];
      if(videoId){
        vid = await this.assignPromise(get('getVideo/id/' + videoId), 'getVideo');
        category_id = await this.assignPromise(get('getVideoCategory/' + videoId), 'getVideoCat');
        if(!Array.isArray(vid) || !vid.length){
          alert("影片不存在");
          throw "影片不存在";
        }
      }else{
      }
      this._setState({
        catOptions: selectOptions(cat, 'displayName', 'id'),
        ...(vid?vid[0]:{}),
        category_id: category_id.map(x=>x.category_id),
        finishedLoading: true,
      });
    }catch(err){
      console.log(err);
      this.props.history.replace('/addVideo');
    }

    // promises.getUser = _get('getUser');
    // promises.getUser.promise.then((res) => {
    //   if (Array.isArray(res)) {
    //     this.setState({
    //       user_info: selectOptions(
    //         res.filter(x => x.id !== curUserId && (permission || !x.position_id)),
    //         x => `${x.classCode ? x.classCode + ' ' : ''}${x.name}`, 'id'
    //       )
    //     })
    //   }
    // }).catch(_r => { });
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {target} = event;
    const {inputType, stateName, info} = event.target.closest('.ui.search, .ui.input, .ui.checkbox, textarea, button').dataset;
    let value = inputHandler(inputType, data);
    if(info){
      if(inputType === 'checkbox'){
        let index;
        switch(target.nodeName){
          case "LABEL": index = target.previousSibling.value; break;
          case "INPUT": index = target.value; break;
          case "DIV": index = target.firstChild.value; break;
          default: return;
        }
        if(index){
          const a = [...this.state.info[stateName]];
          a[+index] = +value;
          this.setState({
            info: {
              ...this.state.info,
              [stateName]: a,
            },
            isBlocking: true,
          });
        }else{
          this.setState({info: {...this.state.info, [stateName]: value}, isBlocking: true});
        }
      }else{
        this.setState({info: {...this.state.info, [stateName]: value}, isBlocking: true})
      }
    }else{
      this.setState({ [stateName]: value, isBlocking: true })
    }
  }

  componentDidUpdate(prevProps) {
    const newUser = this.props.userContext.loggedInUser;
    const oldUser = prevProps.userContext.loggedInUser;
    if (newUser.id !== oldUser.id) {
      this.setState({
        isRedirect: true
      })
    } else {
      if (newUser.desc !== oldUser.desc) {
        this.setState({
          desc: newUser.desc
        })
      }
    }
  }

  addVideo = async () => {
    try{
      const {
        id,
        sourceId,
        title,
        desc,
        category_id,
        info,
        // contributors,
      } = this.state;

      await this._setState({
        isBlocking: false,
      })

      if (category_id.length || window.confirm('加入分類可使其他人更易找到您的影片。您真的不要為影片加入分類？')) {
        this.props.addVideo({
          id,
          sourceId,
          title,
          desc,
          category_id,
          info
          // contributors,
        });
      }
    }catch(err){
      if(!this.isCancelled(err)) console.log(err);
    }
  }

  toUserPanel = () => {
    this.setState({
      isRedirect: true,
      redirectTo: '/userpanel'
    })
  }

  delVideo = () => {
    const {creator_id} = this.state;
    this.props.delVideo(this.state.id).then(x=>{
      if(creator_id){
        this.props.history.push('/user/'+creator_id);
      }else{
        this.props.history.push('/userpanel');
      }
    });
  }

  deleteModalToggle = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen
    });
  }

  render() {
    const {
      isRedirect,
      sourceId,
      title,
      desc,
      category_id,
      catOptions,
      redirectTo,
      info,
      finishedLoading,
      isDeleteModalOpen,
      isBlocking
      // contributors,
      // user_info,
    } = this.state;

    if (isRedirect) return <><BlockerPrompt isBlocking={isBlocking}/><Redirect to={redirectTo} /></>

    if(!finishedLoading) return <Container><Grid><Grid.Row><Grid.Column><EmptySegmentMsg loading={true}/></Grid.Column></Grid.Row></Grid></Container>;

    return (
      <Container>
        <BlockerPrompt isBlocking={isBlocking}/>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as='h1' size='large' textAlign='left'>{this.props.match.params.videoId?'編輯':'新增'}影片</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row textAlign='left'>
            <Grid.Column>
              <Form size='large'>
                <Form.Group>
                  <Form.Input
                    label='YouTube ID'
                    placeholder='YouTube ID'
                    value={sourceId}
                    required
                    data-input-type='text'
                    data-state-name='sourceId'
                    onChange={this.inputChange}
                    width={2}
                    className='form-group-margin'
                  />
                  <Form.Input
                    label='影片標題'
                    placeholder='影片標題'
                    value={title}
                    data-input-type='text'
                    data-state-name='title'
                    onChange={this.inputChange}
                    required
                    width={4}
                    className='form-group-margin'
                  />
                  <Form.TextArea
                    label='影片描述'
                    placeholder='影片描述'
                    value={desc}
                    data-input-type='text'
                    data-state-name='desc'
                    onChange={this.inputChange}
                    width={5}
                    className='form-group-margin'
                  />
                  <Form.TextArea
                    label='學習目標'
                    placeholder='學習目標'
                    value={info.target}
                    data-info='1'
                    data-input-type='text'
                    data-state-name='target'
                    onChange={this.inputChange}
                    width={5}
                    className='form-group-margin'
                  />
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Field className='form-group-margin'>
                    <label>分類</label>
                    <MySelect
                      value={category_id}
                      options={catOptions}
                      multiple
                      search
                      noResultsMessage='找不到分類'
                      data-input-type='select'
                      data-state-name='category_id'
                      onChange={this.inputChange}
                    />
                  </Form.Field>
                  <Form.TextArea
                    label='其他標籤'
                    placeholder='其他標籤'
                    value={info.tags}
                    data-info='1'
                    data-input-type='text'
                    data-state-name='tags'
                    onChange={this.inputChange}
                    className='form-group-margin'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field className='form-group-margin' required width={4}>
                    <label>適合年級</label>
                    {grades.map((g, i) => <Checkbox
                      label={g}
                      key={g}
                      value={i}
                      checked={!!info.grade[i]}
                      name='grade'
                      data-info='1'
                      data-input-type='checkbox'
                      data-state-name='grade'
                      onChange={this.inputChange}
                    />)}
                  </Form.Field>
                  <Form.Field className='form-group-margin' required width={4}>
                    <label>難易程度</label>
                    {difficulties.map((d, i) => <Checkbox
                      label={d}
                      key={d}
                      value={i}
                      checked={!!info.difficulty[i]}
                      name='difficulty'
                      data-info='1'
                      data-input-type='checkbox'
                      data-state-name='difficulty'
                      onChange={this.inputChange}
                    />)}
                  </Form.Field>
                  <Form.Input
                    type='number'
                    label='需時最少'
                    placeholder='需時最少 (分鐘)'
                    value={info.min_minutes}
                    data-info='1'
                    data-input-type='number'
                    data-state-name='min_minutes'
                    onChange={this.inputChange}
                    required
                    className='form-group-margin'
                    width={2}
                  />
                  <Form.Input
                    type='number'
                    label='需時最多'
                    placeholder='需時最多 (分鐘)'
                    value={info.max_minutes}
                    data-info='1'
                    data-input-type='number'
                    data-state-name='max_minutes'
                    onChange={this.inputChange}
                    required
                    className='form-group-margin'
                    width={2}
                  />
                  <Form.Field className='form-group-margin'>
                    <Checkbox
                      label='支援線上授課'
                      checked={!!info.canOnline}
                      name='canOnline'
                      data-info='1'
                      data-input-type='checkbox'
                      data-state-name='canOnline'
                      onChange={this.inputChange}
                      width={2}
                    />
                  </Form.Field>
                  <Form.Field className='form-group-margin'>
                    <Checkbox
                      label='可使用平板上課'
                      checked={!!info.canTablet}
                      name='canTablet'
                      data-info='1'
                      data-input-type='checkbox'
                      data-state-name='canTablet'
                      onChange={this.inputChange}
                      width={2}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  {this.reqs.slice(0,2).map(x=>(
                    <Form.TextArea
                      key={x.fieldName}
                      label={x.displayName}
                      placeholder={x.displayName}
                      value={info[x.fieldName]}
                      data-info='1'
                      data-input-type='text'
                      data-state-name={x.fieldName}
                      onChange={this.inputChange}
                      width={8}
                      className='form-group-margin'
                    />
                  ))}
                </Form.Group>
                <Form.Group>
                  {this.reqs.slice(2).map(x=>(
                    <Form.TextArea
                      key={x.fieldName}
                      label={x.displayName}
                      placeholder={x.displayName}
                      value={info[x.fieldName]}
                      data-info='1'
                      data-input-type='text'
                      data-state-name={x.fieldName}
                      onChange={this.inputChange}
                      width={8}
                      className='form-group-margin'
                    />
                  ))}
                </Form.Group>
                {/* <Form.Group className='form-group-margin' grouped>
                  <Form.Field>
                    <label>參與者</label>
                    <MySelect
                      value={contributors}
                      options={user_info}
                      multiple
                      search
                      noResultsMessage='找不到用戶'
                      data-input-type='select'
                      data-state-name='contributors'
                      onChange={this.inputChange}
                    />
                  </Form.Field>
                </Form.Group> */}
                <Buttons.CancelButton color='orange' content='取消' onClick={this.toUserPanel} />
                <Buttons.SaveButton disabled={!finishedLoading} onClick={this.addVideo} />
                {this.props.match.params.videoId && <Buttons.DeleteButton disabled={!finishedLoading} onClick={this.deleteModalToggle} />}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.props.match.params.videoId && <ConfirmModal
          open={isDeleteModalOpen}
          description='您真的要刪除此影片嗎？'
          cancel={this.deleteModalToggle}
          confirm={this.delVideo}
        />}
      </Container>
    )
  }
}

export default withRouter(withUser(AddVideo));