import React from 'react';
import uniqBy from 'lodash/uniqBy';

import {UserVideo, _Component, EmptySegmentMsg} from '../../Components';
import { get } from '../../Helper/ApiHelper';

class UserPanel_Comment extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
    };
    this.extraRows = [{
      displayName: '評論：',
      content: x=>x.content.map((y,i,a)=>(
        <>
          <p key={i} className='desc-text'>{y}</p>
          {!!(a.length - i - 1) && <hr />}
        </>
      ))
    }]
  }

  componentDidMount() {
    this.assignPromise(get('getCommentVideo/user/' + this.props.userId), 'getUserComment').then(res => {
      return this._setState({
        videos: uniqBy(res, 'video_id') || []
      })
    }).catch(_r => { });
  }

  render() {

    const {
      videos,
    } = this.state;

    if(!videos) return <EmptySegmentMsg loading={true}/>
    if(!videos.length) return <EmptySegmentMsg msg="此用戶未有任何留言"/>

    return videos.map(x => (
      <UserVideo
        key={x.video_id}
        video={x}
        extraRows={this.extraRows}
      />
    ))
  }
}

export default UserPanel_Comment;