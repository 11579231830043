/* import react */
import React from 'react';

/* import semantic-ui element */
import { Table } from 'semantic-ui-react';

const ResponsiveTable = (props) => {
    const responsiveHeaderWidth = props.columnInfo.reduce((prev,cur)=>Math.max(prev,cur.length),0)+2;
    const trueProps = {...props};
    ['breakPoint','columnInfo'].forEach(x=>{
        delete(trueProps[x]);
    });
    return (
      <>
        <style>
        {`@media only screen and (max-width: ${props.breakPoint||640}px) {
            table, thead, tbody, th, td, tr {
                display: block;
            }
            thead tr {
                display:none;
            }
            tr {
                border: 1px solid #ccc;
                display: flex;
                flex-direction: column;
            }
            td {
                border: none;
                border-left: 1px solid #eee !important;
                position: relative;
                margin-left: ${responsiveHeaderWidth}rem;
            }
            td, td:before{
                border-top: 1px solid rgba(255,255,255,.1)!important;
            }
            td:before {
                position: absolute;
                display: flex;
                top: 50%;
                height: 100%;
                width: ${responsiveHeaderWidth}rem;
                left: 0;
                transform: translate(-100%,-50%);
                white-space: nowrap;
                justify-content: center;
                align-items: center;
                box-sizing: content-box;
            }
            ${props.columnInfo.map((x,i)=>`td:nth-of-type(${i+1}):before { content: "${x}"; }`).join('\n')}
        }`}
        </style>
        <Table {...trueProps}/>
      </>
    )
}

export default ResponsiveTable;