import React, {memo} from 'react';
import { Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const Avatar = (props) => {
  const size = props.size || 'mini';
  const link = props.link || (props.user_id ? `/user/${props.user_id}/` : null);
  const { headerSize, inline } = props;
  const fullName = props.name || '';
  const imgPath = props.imgUrl || 'blank.png';

  let text = null;
  const avatarNameClass = `avatar-name${inline ? ' inline' : ''}`;

  if (headerSize) {
    text = (<Header as={headerSize} className={avatarNameClass}>{fullName}</Header>);
  } else {
    text = (<div className={avatarNameClass}>{fullName}</div>);
  }

  const innerContent = (<>
    <div className={`ui centered image ${size}${inline ? ' inline' : ''}`}>
      <div className='avatar' style={{
        'backgroundImage': `url(/avatars/${imgPath})`,
      }} />
    </div>{text}
  </>);

  if (link)
    return (
      <Link to={link} className={`avatar-wrap${inline ? ' no-flex' : ''}`}>
        {innerContent}
      </Link>
    );
  else
    return (
      <div className={`avatar-wrap${inline ? ' no-flex' : ''}`}>
        {innerContent}
      </div>
    );
}

export default memo(Avatar);