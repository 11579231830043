/* import react */
import React from 'react';

/* import router component */
import Router from './Router/Router';
import UserProvider from './Context/UserProvider';
import SpinnerProvider from './Context/SpinnerProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { Spinner } from './Components';

const App = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
    <UserProvider>
      <SpinnerProvider>
        <Spinner />
        <Router />
      </SpinnerProvider>
    </UserProvider>
  </GoogleOAuthProvider>
);

export default App;