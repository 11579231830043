/* import react */
import React from 'react';

/* import components */
import {UserVideo, _Component} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import findIndex from 'lodash/findIndex';

/* import semantic-ui element */
import { Grid, Button, Header } from 'semantic-ui-react';

class Featured extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      count: 0,
    }
    this.extraRows = [{
      displayName: x => x.index ? (
        <div className='float-left'>現時位置<Header as="h4" color="red">#{x.index + 1}</Header></div>) : '行動',
      content: x=>x.index ? (
        <Button color='red' icon='remove' onClick={this.removeVideo(x.id)} circular floated='right' />
      ) : (
        <Button color='blue' icon='add' onClick={this.addVideo(x.id)} circular floated='right' />
      )
    }]
  }

  update = async () => {
    try{
      const videos = await this.assignPromise(get('getVideo'), 'getVideo');
      const videoMap = new Map(videos.map(x=>[x.id,x]));
      const selectedVideos = await this.assignPromise(get('getFeatured'), 'getFeatured');
      for(let i=0;i<selectedVideos.length;i++){
        videoMap.get(selectedVideos[i].video_id).index = i;
      }
      await this._setState({
        videos,
        count: selectedVideos.length
      });
    }catch(err){
      if (!this.isCancelled(err)) console.log("Error when fetching featured vid", err);
    }
  }

  componentDidMount = () => {
    this.update();
  }

  addVideo = (id) => () => {
    const index = findIndex(this.state.videos, {id});
    if(index>=0){
      const videos = [...this.state.videos];
      videos[index] = {...videos[index], index: this.state.count}
      this.setState({
        videos,
        count: this.state.count+1
      })
    }
  }

  removeVideo = (id) => () => {
    const index = findIndex(this.state.videos, {id});
    if(index>=0){
      const videos = [...this.state.videos];
      videos[index] = {...videos[index], index: undefined}
      this.setState({
        videos,
        count: this.state.count-1
      })
    }
  }

  clearAll = () => {
    this.setState({
      videos: this.state.videos.map(x=>({...x, index: undefined})),
    })
  }

  /* save featured */
  save = () => {
    const {
      videos
    } = this.state;

    let data = {
      selectedVideos: videos.filter(x=>x.index).map(x=>x.index)
    }

    this.assignPromise(post('setFeatured', data), 'setFeatured').then((result) => {
      if (result && result.status) {
        this.update();
        alert('已成功儲存');
      } else {
        alert('本頁面資料已過時，現在將重新取得資料');
        this.update();
      }
    }).catch(_r => { });
  }

  render() {
    const {
      videos,
    } = this.state;

    return (
      <Grid columns={3}>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Button color='orange' content='全部取消' icon='remove' floated='right' onClick={this.clearAll} />
            <Button color='green' content='儲存' icon='save' floated='right' onClick={this.save} />
          </Grid.Column>
        </Grid.Row>
        {videos.map(x => {
          return (
            <Grid.Column key={x.id}>
              <UserVideo
                video={x}
                noLink={true}
                mobileOnly={true}
                selected={!!x.index}
                extraRows={this.extraRows}
              />
            </Grid.Column>
          )
        }
        )}
      </Grid>
    )
  }
}

export default Featured;