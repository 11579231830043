/* import react */
import React from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  _Component,
  VideoSegment,
  EmptySegmentMsg
} from '../../Components';

import { get } from '../../Helper/ApiHelper';
import _get from 'lodash/get';

class CategoryPanel extends _Component {
  constructor(props) {
    super(props);
    const categoryId = props.category_id || props.match.params.categoryId;

    this.state = {
      title: props.title || '',
      categoryId,
      checkedVideos: false,
      videos: [],
    };
  }
  getAllInfo = async () => {
    const {categoryId} = this.state;

    try{
      const [videos, cat] = await Promise.all([
        this.assignPromise(get('getVideo/category/' + categoryId), 'getVideo'),
        this.assignPromise(get('getCategory/' + categoryId), 'getCategory'),
      ]);
      this.setState({
        checkedVideos: true,
        videos,
        title: _get(cat, [0, 'displayName'], '')
      })
    }catch(err){
      if(!err || typeof err !== 'object' || !err.isCanceled) console.log("Error when get all info for cat panel", err);
    }
  };

  componentDidMount() {
    this.getAllInfo();
  }

  componentDidUpdate(prevProps) {
    const newId = this.props.category_id || this.props.match.params.categoryId;
    const oldId = prevProps.category_id || prevProps.match.params.categoryId;
    if (newId !== oldId) {
      this.setState({ categoryId: newId }, this.getAllInfo);
    }
  }

  render() {

    const {
      title,
      videos,
      categoryId,
      checkedVideos
    } = this.state;

    if (this.props.match.params.categoryId && checkedVideos && !videos.length) {
      return (<div>
        {<EmptySegmentMsg msg='此分類未有任何影片' />}
      </div>)
    }

    return (
      <VideoSegment
        key={categoryId}
        title={title}
        videoList={videos}
        showAvatar={false}
        limit={this.props.limit}
        titleLink={this.props.titleLink}
      />
    )
  }
}

export default withRouter(CategoryPanel);