import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";

import { FullscreenDimmer, _Component } from "../Components";
import { grades, difficulties } from "../Const";
import { get } from "../Helper/ApiHelper";
import { Menu, Image, Dropdown, Responsive, Label } from "semantic-ui-react";
import Avatar from "./Avatar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { UserContext } from "../Context/user-context";

const MenuItems = ({
  categories,
  finishedLoading,
  systemCats,
  navigation,
  mode = "desktop",
}) => {
  const { pathname } = useLocation();
  const { categoryId } = useParams();

  return (
    finishedLoading && (
      <>
        {/* <Menu.Item
      content='專題'
      href='/recommended'
      onClick={navigation}
      active={pathname.indexOf('/recommended') !== -1}
    />

    <Menu.Item
      content='最新內容'
      href='/newest'
      onClick={navigation}
      active={pathname.indexOf('/newest') !== -1}
    /> */}
        {categories.map(
          (sc) =>
            !!sc.items.length && (
              <Dropdown
                simple={mode === "desktop"}
                scrolling
                item
                key={sc.id}
                text={sc.displayName}
                onClick={mode === "desktop" ? navigation : undefined}
                href={mode === "desktop" ? `/group/${sc.id}` : undefined}
                className={
                  pathname.indexOf(`/group/${sc.id}`) !== -1
                    ? "fake_active"
                    : ""
                }
              >
                <Dropdown.Menu>
                  {sc.items.map((c) => (
                    <Dropdown.Item
                      key={c.id}
                      text={c.displayName}
                      href={`/category/${c.id}`}
                      onClick={navigation}
                      active={+categoryId === c.id}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )
        )}

        <Dropdown
          className="unset-cursor"
          simple={mode === "desktop"}
          scrolling
          item
          text="適合年級"
        >
          <Dropdown.Menu>
            {grades.map((c) => (
              <Dropdown.Item
                key={c}
                text={c}
                href={`/grade/${c}`}
                onClick={navigation}
                active={+categoryId === c.id}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          className="unset-cursor"
          simple={mode === "desktop"}
          scrolling
          item
          text="難易程度"
        >
          <Dropdown.Menu>
            {difficulties.map((c) => (
              <Dropdown.Item
                key={c}
                text={c}
                href={`/difficulty/${c}`}
                onClick={navigation}
                active={pathname.indexOf(`/difficulty/${c}`) !== -1}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {systemCats.map((c) => (
          <Menu.Item
            key={c.id}
            content={c.displayName}
            href={`/category/${c.id}`}
            onClick={navigation}
            active={pathname.indexOf(`/category/${c.id}`) !== -1}
          />
        ))}

        <Menu.Item
          icon={mode === "desktop" ? "search" : undefined}
          content={mode === "desktop" ? undefined : "搜尋"}
          href="/search"
          onClick={navigation}
          active={pathname.indexOf("/search") !== -1}
        />
        <Menu.Item
          content={
            <Label
              color="orange"
              basic
              size="big"
              href="https://coding101.hk"
              target="_blank"
              className="no-margin contact-us"
            >
              聯絡我們
            </Label>
          }
        />
      </>
    )
  );
};

const Nav = () => {
  const history = useHistory();
  const { userContext } = useContext(UserContext);
  const { pathname } = useLocation();
  const [mobileSubMenuOpen, setMobileSubMenuOpen] = useState(false);
  const [state, setState] = useState({
    categories: [],
    systemCats: [],
    finishedLoading: false,
    dimmerOpen: false,
    loginErrorMsg: false,
  });

  const _login = useGoogleLogin({
    prompt: "select_account",
    onSuccess: ({ access_token }) => {
      document.activeElement.blur();
      setMobileSubMenuOpen(false);
      userContext.pingUser(access_token);
    },
    onNonOAuthError: (err) => {
      console.log(err);
      setState({
        dimmerOpen: false,
        loginErrorMsg: err.type === "popup_closed" ? [] : ["請再試一次"],
      });
    },
    onError: (err) => {
      console.log(err);
      setState((prevState) => ({
        ...prevState,
        dimmerOpen: false,
        loginErrorMsg: "請再試一次",
      }));
    },
  });

  const login = useCallback(() => {
    document.activeElement.blur();
    _login();
  }, [_login]);

  useEffect(() => {
    const fetchCat = async () => {
      try {
        const [categories, systemCats] = await Promise.all([
          get("getCategoryForNav"),
          get("getSystemCategory"),
        ]);

        setState((prevState) => ({
          ...prevState,
          finishedLoading: true,
          categories,
          systemCats,
        }));
      } catch (err) {
        if (err && typeof err === "object" && err.isCanceled) return;
        setState((prevState) => ({
          ...prevState,
          finishedLoading: true,
        }));
        console.log("Error when fetching cat for Nav", err);
      }
    };
    fetchCat();
  }, [pathname]);

  const navigation = (eventOrRoute) => {
    if (typeof eventOrRoute === "string") {
      history.push(eventOrRoute);
      return;
    }
    const { href } = eventOrRoute.target.closest(".item");
    setMobileSubMenuOpen(false);
    document.activeElement.blur();
    history.push("/" + href.split("/").slice(3).join("/"));
    eventOrRoute.preventDefault();
  };

  const logout = () => {
    document.activeElement.blur();
    userContext.resetUser();
    setMobileSubMenuOpen(false);
  };

  const toggleSubMenu = useCallback(() => {
    setMobileSubMenuOpen((x) => !x);
  }, []);

  const { loggedInUser, getPermission, userValid } = userContext;

  const accessSystemPanel = getPermission("accessSystemPanel");

  const { dimmerOpen } = state;

  return (
    <div id="top-nav">
      <FullscreenDimmer active={dimmerOpen} isLoading={true} />
      <Menu size="huge" borderless id="top-nav-bar">
        <Menu.Item
          content={
            <Image
              src="/logo.png"
              id="nav-logo"
              size="small"
              title={process.env.REACT_APP_NAME}
            />
          }
          href="/recommended"
          onClick={navigation}
        />
        <Responsive as={Fragment} minWidth={1024}>
          <MenuItems navigation={navigation} {...state} mode="desktop" />

          {userValid ? (
            <Dropdown
              simple
              scrolling
              item
              trigger={
                <Avatar
                  {...loggedInUser}
                  link="/userpanel/"
                  onClick={navigation}
                />
              }
              className="right"
            >
              <Dropdown.Menu direction="left">
                {accessSystemPanel && (
                  <Dropdown.Item
                    text="系統管理"
                    href="/systemPanel/video"
                    onClick={navigation}
                  />
                )}
                <Dropdown.Item
                  text="用戶頁面"
                  href="/userpanel/"
                  onClick={navigation}
                />
                <Dropdown.Item text="登出" onClick={logout} />
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Dropdown simple scrolling item text="登入" className="right">
              <Dropdown.Menu direction="left">
                <Dropdown.Item text="以 Google 登入" onClick={login} />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Responsive>
        <Responsive as={Fragment} maxWidth={1023} minWidth={361}>
          <Menu.Menu position="right">
            {userValid && (
              <Menu.Item href="/userpanel/" onClick={navigation}>
                <Avatar size="mini" inline={false} {...loggedInUser} />
              </Menu.Item>
            )}
            <Menu.Item
              icon="sidebar"
              onClick={toggleSubMenu}
              id="mobile-toggle"
            />
          </Menu.Menu>
        </Responsive>
        <Responsive as={Fragment} maxWidth={360}>
          <Menu.Item position="right" onClick={toggleSubMenu}>
            <Avatar size="mini" inline={false} {...loggedInUser} />
          </Menu.Item>
        </Responsive>
      </Menu>
      {
        <Responsive as={Fragment} maxWidth={1023}>
          <Menu
            size="huge"
            stackable
            id="top-mobile-nav"
            className={mobileSubMenuOpen ? "open" : "close"}
          >
            <MenuItems navigation={navigation} {...state} mode="mobile" />

            {accessSystemPanel && (
              <Menu.Item
                content="系統管理"
                href="/systemPanel/video"
                onClick={navigation}
              />
            )}

            {userValid ? (
              <>
                <Menu.Item
                  content="用戶設定"
                  href="/userpanel"
                  onClick={navigation}
                />
                <Menu.Item content="登出" onClick={logout} />
              </>
            ) : (
              <Menu.Item content="以 Google 登入" onClick={login} />
            )}
          </Menu>
        </Responsive>
      }
    </div>
  );
};

export default Nav;
