import React from 'react';
import { SpinnerContext } from "./spinner-context";
import {_Component} from "../Components";
export default class SpinnerProvider extends _Component {
	constructor() {
		super();
		this.default = {
			show: false,
		};
		this.state = {
			showSpinner: this.showSpinner.bind(this),
			hideSpinner: this.hideSpinner.bind(this),
		}
	}
		
	showSpinner(callback=()=>{}) {
		this.setState({ show: true }, callback);
	}
	hideSpinner(callback=()=>{}){
		this.setState({ show: false }, callback);
	}
	render() {
		return (
			<SpinnerContext.Provider
				value={{
					spinnerContext: {
						...this.state
					}
				}}
			>
				{this.props.children}
			</SpinnerContext.Provider>
		);
	}
}
