import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import _get from 'lodash/get';

import KOLStarRatings from './KOLStarRatings';
import _Component from './_Component';

/* import semantic-ui element */
import {
  Grid,
  Button,
  Table,
  Responsive,
  Rail,
  Segment
} from 'semantic-ui-react';

import SquareLabel from './SquareLabel';

class UserVideo extends _Component {
  constructor(props) {
    super(props);

    this.state = {
      hasVideo: true,
      thumbnail: '/img/default-video.png',
      isOwnUser: props.location.pathname.match(/userpanel\/?$/),
    }
  }

  update = async (sourceId) => {
    try{
      const thumbnail = await Promise.resolve(`https://img.youtube.com/vi/${sourceId}/0.jpg`);//this.assignPromise(get('getVideoThumbnail/' + (sourceId || this.props.video.sourceId)), 'getVideoThumbnail');

      this._setState({
        thumbnail
      })
    }catch(err){
      if (!this.isCancelled(err)) console.log("Error when updating user video", err);
    }
  }

  componentDidMount() {
    this.update(_get(this.props, ['video', 'sourceId'], ''));
  }

  componentDidUpdate(prevProps) {
    const oldSrc = _get(prevProps, ['video', 'sourceId'], '');
    const newSrc = _get(this.props, ['video', 'sourceId'], '');
    if (oldSrc !== newSrc) {
      this.update(newSrc);
    }
  }

  pend_approve = () => <Button color="red" compact className='pend_approval'>待批核</Button>;

  render() {
    const {
      isOwnUser,
      thumbnail,
    } = this.state;

    const {
      video:{
        id,
        status,
        views,
        title,
        rating,
        category,
        comment,
      },
    
      extraRows,
      noLink,
      mobileOnly,
      selected
    } = this.props;

    if (!isOwnUser && !status) {
      return false;
    }

    const trueCategory = category || <span className='uncategorized'>未分類</span>

    const desktopContent = (
      <Grid columns={2} textAlign='left' centered>
        <Grid.Column className='user-video-image-col' key='image' width={4}>
          <SquareLabel imgPath={thumbnail} />
        </Grid.Column>
        <Grid.Column className='user-video-detail-col' key='detail' width={8} stretched>
          <Table fixed unstackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={6}>片名：</Table.Cell>
                <Table.Cell width={10}>{title}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>主題：</Table.Cell>
                <Table.Cell width={10}>{trueCategory}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>點撃次數：</Table.Cell>
                <Table.Cell width={10}>{status ? views || 0 : '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>平均評分：</Table.Cell>
                <Table.Cell width={10}>
                  <KOLStarRatings
                    rating={+rating}
                    starDimension='1.25rem'
                    starSpacing='0.125rem'
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>評論數：</Table.Cell>
                <Table.Cell width={10}>{status ? comment || 0 : '-'}</Table.Cell>
              </Table.Row>
              {
                !!extraRows && extraRows.map((x, i) => (
                  <Table.Row key={i}>
                    <Table.Cell width={6}>{typeof x.displayName === 'string' ? x.displayName : x.displayName(this.props.video)}</Table.Cell>
                    <Table.Cell width={10}>{typeof x.content === 'string' ? this.props.video[x.content] : (typeof x.content === 'function' && x.content(this.props.video))}</Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
          {!!isOwnUser && !status && this.pend_approve()}
        </Grid.Column>
      </Grid>
    );

    const mobileContent = (
      <Segment basic className={selected ? 'selected' : ''}>
        <SquareLabel imgPath={thumbnail} />
        <Table fixed unstackable attached='bottom'>
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={3}>片名：{title}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan={3}>主題：{trueCategory}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>點撃次數<br />{views}</Table.Cell>
              <Table.Cell width={5}>平均評分<br />
                <KOLStarRatings
                  rating={+rating}
                  starDimension='1rem'
                  starSpacing='0'
                />
              </Table.Cell>
              <Table.Cell width={5}>評論數<br />{comment}</Table.Cell>
            </Table.Row>
            {
              !!extraRows && extraRows.map((x,i) => (
                <Table.Row key={i}>
                  <Table.Cell colSpan={3}>{typeof x.displayName === 'string' ? x.displayName : x.displayName(this.props.video)}{typeof x.content === 'string' ? this.props.video[x.content] : (typeof x.content === 'function' && x.content(this.props.video))}</Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
        {!!isOwnUser && !status && (
          <Rail attached internal position='left'>
            {this.pend_approve()}
          </Rail>)}
      </Segment>
    );

    const content = mobileOnly ? mobileContent : (
      <>
        <Responsive as={Fragment} minWidth={641}>
          {desktopContent}
        </Responsive>
        <Responsive as={Fragment} maxWidth={640}>
          {mobileContent}
        </Responsive>
      </>
    );

    return (<Grid.Row key={id}><Grid.Column>
      {noLink?content:(<Link to={isOwnUser ? `/editvideo/${id}` : `/video/${id}`} className="user-video">{content}</Link>)}
      </Grid.Column></Grid.Row>
    );
  }
}

export default withRouter(UserVideo);