/* import lodash */
import find from 'lodash/find';
import pick from 'lodash/pick';
import keyBy from 'lodash/keyBy';

/* import react */
import React from 'react';
import { withRouter } from 'react-router-dom';

import { withUser } from '../../Context/withUser';

/* import components */
import {ErrorLabel, ConfirmModal, FileInputInstantUpload, FullscreenDimmer, EmptyTableMsg, _Component, ResponsiveTable} from '../../Components';

import { excelFormats } from '../../Const';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { selectOptions } from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';
import { tableSorting } from '../../Helper/Helper';
import { floatRight } from '../../Helper/StyleHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Checkbox, Input } from 'semantic-ui-react';

const tableHeader = [
  { displayName: '職位', columnName: 'displayName' },
  { displayName: '姓名', columnName: 'name' },
  { displayName: '電郵', columnName: 'email' },
];

class User extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      position: [],
      teacherInfo: [],

      editInfo: {},
      isEditModalOpen: false,
      isInactivateModalOpen: false,
      isDeleteModalOpen: false,

      selectedId: null,
      position_id: null,
      name: '',
      email: '',

      sortColumn: null,
      sortDirection: null,

      showHidden: false,
    }
    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        email: '請輸入有效的電郵',
        default: '請輸入資料'
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  update = async () => {
    try {
      const [position, teacherInfo] = await Promise.all([
        get('getPosition/'),
        get('getAllTeacherWithAdmin/')
      ]);
      const positionLookup = keyBy(position, 'id');
      await this.setStateAsync({
        position: selectOptions(position, 'displayName', 'id'),
        positionLookup,
        teacherInfo: teacherInfo.map(x => ({
          ...x,
          displayName: (positionLookup[x.position_id] || { displayName: '' }).displayName
        }))
      });
    } catch (err) {
      console.log("update Err", err)
    }
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.update();
    this.setStateAsync({
      finishedLoading: true,
    });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value })
  }

  /* modal toggle */
  modalToggle = (modalStateName, selectedId = null) => () => {
    this.validator.hideMessages();
    let editObject = {
      position_id: null,
      name: '',
      email: '',
    };

    if (selectedId !== null) {
      const { teacherInfo } = this.state;
      editObject = find(teacherInfo, { id: selectedId });
    }

    this.setStateAsync({
      ...pick(editObject, ['position_id', 'name', 'email']),
      selectedId,
      [modalStateName]: !this.state[modalStateName]
    });
  }

  /* save teacher */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    let data = Object.assign(pick(this.state, ['position_id', 'name', 'email']), {
      enabled: 1, //default shown
    });

    if (this.state.selectedId) {
      data.id = this.state.selectedId;
    }
    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editUser/', data, 'FORMDATA');
      if (result && result.status) {
        await this.update();
        this.modalToggle('isEditModalOpen')();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== "unmounted") {
        alert(`無法儲存教師${typeof err === 'string' ? '：' + err : ''}`);
        console.log("Error when saving teacher", err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false
    });
  }

  activate = async () => {
    let data = { id: this.state.selectedId, enabled: 1 };
    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editUser/', data, 'FORMDATA');
      if (result && result.status) {
        await this.update();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== 'unmounted') {
        alert('將教師設為在校時發生錯誤');
        console.log("Error when activating teacher", err);
      }
    }
    this.modalToggle('isActivateModalOpen')();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false
    });
  }

  inactivate = async () => {
    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editUser/', { id: this.state.selectedId, enabled: null }, 'FORMDATA');
      if (result && result.status) {
        await this.update();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== 'unmounted') {
        alert('將教師設為退校時發生錯誤');
        console.log("Error when inactivating teacher", err);
      }
    }
    this.modalToggle('isInactivateModalOpen')();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false
    });
  }

  /* delete teacher */
  delete = async () => {
    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('deleteUser', { id: this.state.selectedId });
      if (result && result.status) {
        await this.update();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== 'unmounted') {
        alert('刪除教師時發生錯誤');
        console.log("Error when deleting teacher", err);
      }
    }
    this.modalToggle('isDeleteModalOpen')();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false
    });
  }

  /* table sorting */
  sorting = (clickedColumn) => () => {
    const { teacherInfo, sortColumn, sortDirection } = this.state;
    const sortingResult = tableSorting(clickedColumn, teacherInfo, sortColumn, sortDirection, {
      'classNo': true,
    });
    this.setStateAsync({
      sortColumn: clickedColumn,
      sortDirection: sortingResult.sortDirection,
      teacherInfo: sortingResult.data
    });
  }

  fileHandling = async (xlsFile) => {
    //file parse
    const reader = new FileReader();
    try {
      const XLSX = await import('xlsx');
      const result = await new Promise((res, rej) => {
        reader.onload = (evt) => { //evt = on_file_select event
          /* Parse data */
          const binaryString = evt.target.result;
          const workBook = XLSX.read(binaryString, { type: 'binary' });
          /* Get first worksheet, object {[cellAddress]: value} */
          const wsname = workBook.SheetNames[0];
          const data = workBook.Sheets[wsname];
          this.handleXLSX(data).then(res).catch(err => { rej(err); });
        };
        reader.onerror = (evt) => {
          console.log('err', evt);
          rej('讀取檔案時發生錯誤');
        }
        reader.readAsBinaryString(xlsFile);
      });
      return result;
    } catch (err) {
      return { status: false, err }
    }
  }

  handleXLSX = async (data) => {
    const { permission, loggedInUser: { email, name } } = this.props.userContext;

    const colMap = {
      position: 'A',
      name: 'B',
      email: 'C',
    };
    const rowCount = +data['!ref'].match(/\d+$/g);
    const result = {
      data: [],
    };
    let dataHasCurrentUser = false;
    //1 is for title
    const emailSet = new Set();
    for (var i = 2; i <= rowCount; i++) {
      const resultRow = {};
      if (!data['A' + i]) {
        break;
      }
      if (!data['C' + i]) {
        throw `列#${i}電郵無效`;
      }
      if (emailSet.has(data['C' + i].w)) {
        throw `列#${i}電郵重複`;
      }
      emailSet.add(data['C' + i].w);

      for (let field in colMap) {
        resultRow[field] = data[colMap[field] + i].w;
      }
      if (resultRow.email === email)
        dataHasCurrentUser = true;
      resultRow.enabled = 1;
      result.data.push(resultRow);
    }
    if (!dataHasCurrentUser) {
      result.data.push({
        position: permission && permission.displayName,
        email,
        name,
        enabled: 1
      });
    }
    try {
      const response = await post('batchEditTeacher/', result);
      if (response && response.status) {
        this.update();
      }
      return response;
    } catch (err) {
      console.log('Err caught finally', err);
    }
  }

  toUserPage = (event) => this.props.history.push(`/user/${event.target.closest('td').dataset.userId}`)

  render() {
    const {
      teacherInfo,
      isEditModalOpen,
      isActivateModalOpen,
      isInactivateModalOpen,
      isDeleteModalOpen,
      name,
      email,
      position,
      position_id,
      sortColumn,
      sortDirection,
      showHidden,
      finishedLoading,
      dimmerOpen
    } = this.state;

    let trueteacherInfo = [];
    if (teacherInfo) {
      trueteacherInfo = teacherInfo.filter(x => (showHidden || x.enabled));
    }

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Checkbox label='顯示已停用老師' checked={showHidden} onChange={this.inputChange('checkbox', 'showHidden')} />
              <Input value={undefined} placeholder='' disabled style={{ opacity: 0, width: 1 }} />
              <FileInputInstantUpload
                disabled={!finishedLoading}
                buttonColor='green'
                buttonText='上載教師檔案'
                formatErrorText='請上載正確格式(xlsx)'
                accept={excelFormats}
                wrapperStyle={floatRight}
                fileHandling={this.fileHandling}
              />
              <Button
                disabled={!finishedLoading}
                color='green'
                content='新增教師'
                icon='add'
                floated='right'
                onClick={this.modalToggle('isEditModalOpen')}
                circular
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ResponsiveTable textAlign='center' sortable selectable celled unstackable columnInfo={tableHeader.map(x => x.displayName).concat('行動')}>
                <Table.Header>
                  <Table.Row>
                    {tableHeader.map(({ displayName, columnName }, index) => {
                      return (
                        <Table.HeaderCell key={index} sorted={sortColumn === columnName ? sortDirection : null} onClick={this.sorting(columnName)}>
                          {displayName}
                        </Table.HeaderCell>
                      )
                    })}
                    <Table.HeaderCell>行動</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {finishedLoading ? trueteacherInfo.length > 0 ?
                    <>
                      {trueteacherInfo.map(({ id, name, email, displayName, enabled }) => (
                        <Table.Row className={enabled ? '' : 'left-school'} key={email}>
                          <Table.Cell
                            className='cursor'
                            data-user-id={id}
                            onClick={this.toUserPage}
                          >{displayName}</Table.Cell>
                          <Table.Cell
                            className='cursor'
                            data-user-id={id}
                            onClick={this.toUserPage}
                          >{name}</Table.Cell>
                          <Table.Cell
                            className='cursor'
                            data-user-id={id}
                            onClick={this.toUserPage}
                          >{email}</Table.Cell>
                          <Table.Cell>
                            <Button
                              color='blue'
                              icon='edit'
                              disabled={!enabled}
                              onClick={this.modalToggle('isEditModalOpen', id)}
                              circular
                            />
                            <Button
                              color={enabled ? 'orange' : 'green'}
                              disabled={id === this.getCurrentUserId()}
                              icon={enabled ? 'eye slash' : 'eye'}
                              onClick={enabled ? this.modalToggle('isInactivateModalOpen', id) : this.modalToggle('isActivateModalOpen', id)}
                              circular
                            />
                            <Button
                              color='red'
                              icon='delete'
                              disabled={id === this.getCurrentUserId()}
                              onClick={this.modalToggle('isDeleteModalOpen', id)}
                              circular
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </>
                    : <EmptyTableMsg colSpan='4' /> : <EmptyTableMsg colSpan='4' msg='資料載入中' />
                  }
                </Table.Body>
              </ResponsiveTable>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? (<FullscreenDimmer active={dimmerOpen} isLoading={true} />) : <>
          <Modal open={isEditModalOpen} closeOnDimmerClick={false} onClose={this.modalToggle('isEditModalOpen')}>
            <Modal.Header>請輸入老師資料</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  <Form.Group className='form-group-margin' grouped>
                    <Form.Input value={name} onChange={this.inputChange('text', 'name')} label='姓名' placeholder='姓名' />
                    {this.validator.message('name', name, 'required')}
                  </Form.Group>
                  <Form.Group className='form-group-margin' grouped>
                    <Form.Input value={email} onChange={this.inputChange('text', 'email')} label='電郵' placeholder='電郵' />
                    {this.validator.message('email', email, 'required|email')}
                  </Form.Group>
                  <Form.Group className='form-group-margin' grouped>
                    <Form.Select value={position_id} onChange={this.inputChange('text', 'position_id')} options={position} label='職位' placeholder='職位' />
                    {this.validator.message('position_id', position_id, 'required')}
                  </Form.Group>
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
              <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
            </Modal.Actions>
          </Modal>

          <ConfirmModal open={isActivateModalOpen} description='確定將老師轉回為「非停用」並預設顯示？' cancel={this.modalToggle('isActivateModalOpen')} confirm={this.activate} confirmText='取消停用' confirmIcon='eye' />
          <ConfirmModal open={isInactivateModalOpen} description='確定將老師轉為「已停用」並預設隱藏？' cancel={this.modalToggle('isInactivateModalOpen')} confirm={this.inactivate} confirmText='停用' confirmIcon='eye slash' />
          <ConfirmModal open={isDeleteModalOpen} description='確定刪除老師？本操作不可逆轉！' cancel={this.modalToggle('isDeleteModalOpen')} confirm={this.delete} />
        </>}
      </>
    )
  }
}

export default withRouter(withUser(User));