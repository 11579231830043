/* import lodash */
import sortBy from 'lodash/sortBy';

/* sorting table */
export const tableSorting = (clickedColumn, data = [], sortColumn, sortDirection, intSort = {}) => {
  if (sortColumn !== clickedColumn) {
    return {
      sortDirection: 'ascending',
      data: sortBy(data, intSort.hasOwnProperty(clickedColumn) ? (o) => +o[clickedColumn] : [clickedColumn]),
    };
  }

  return {
    data: data.reverse(),
    sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending',
  };
}

/* map options for select box */
export const selectOptions = (result, textField, valueField) => {
  switch ((typeof (textField) === 'function') * 2 + (typeof (valueField) === 'function')) {
    case 0: return result.map(record => ({ text: record[textField], value: record[valueField] }));
    case 1: return result.map(record => ({ text: record[textField], value: valueField(record) }));
    case 2: return result.map(record => ({ text: textField(record), value: record[valueField] }));
    case 3: return result.map(record => ({ text: textField(record), value: valueField(record) }));
    default: return [];
  }
}

/* mark options for select box */
export const markSelectOptions = (min, max, step) => {
  let options = [];
  for (let i = min; i <= max; i += step) {
    options.push({ text: i, value: i })
  }
  return options;
}

/* map each array content to text,value pair (same) */
export const arrayToOption = (ar) => ar.map(x => ({ text: x, value: x }))

/* map each array content to text,value pair (same) */
export const arrayToSearch = (ar) => ar.map(x => ({ title: x }))