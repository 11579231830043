/* import react */
import React from 'react';
import { Link } from "react-router-dom";

import { get } from '../Helper/ApiHelper';
import KOLStarRatings from './KOLStarRatings';
import { difficulties, diffColors } from '../Const';

/* import semantic-ui element */
import {
  Header,
  Segment,
  Table,
  Icon,
  Label
} from 'semantic-ui-react';

import NewLabel from './NewLabel';
import _Component from './_Component';

//PLAN: gets the video object on render

class RelatedBriefVideo extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnail: '/img/default-video.png',
      thumbnailObj: {backgroundImage: `url(/img/default-video.png)` },
      rating: null,
      categories: "",
    }
  }

  fetch = async () => {
    try{
      const [catAndRat, thumbnail] = await Promise.all([
        this.assignPromise(get('getCategoryAndRating/'+ this.props.id), 'getCategoryAndRating'),
        Promise.resolve(`https://img.youtube.com/vi/${this.props.sourceId}/0.jpg`),
      ]);

      await this._setState({
        ...(catAndRat[0]||{}),
        thumbnail,
        thumbnailObj: {backgroundImage: `url(${thumbnail})` }, 
      });
    }catch(err){

    }
  }

  componentDidMount = async () => {
    this.fetch();
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.id !== this.props.id){
      this.fetch();
    }
  }

  render() {
    const { id,
      views,
      time,
      title,
      desc,
      info,
     } = this.props;

    const {
      thumbnailObj,
      rating,
      categories,
    } = this.state;

    let innerStuff = (<>
      <Header className="video-title" title={title}>
        {title}
      </Header>
      <div className='video-info'>
        <KOLStarRatings rating={+rating} starDimension='1.25rem' starSpacing='0.125rem' className='right-padded'/>
        <NewLabel time={time} isGridView={true} className='right-padded'/>
        {views != null && <span className='right-padded'><Icon name='eye'/>{+views}</span>}
        <span className='no-wrap'>{difficulties.map((x,i)=>!!info.difficulty[i] && <Label key={x} color={diffColors[i]} as='span'>{x}</Label>)}</span>
        <div className='video-excerpt'>{desc}</div>
      </div>
      {!!categories && (<Table celled fixed singleLine title={categories}>
        <Table.Body><Table.Row><Table.Cell>{categories}</Table.Cell></Table.Row></Table.Body>
      </Table>)}
    </>);
    return (
      <Link to={`/video/${id}`} className="brief-video related">
        <div className="square-thumbnail" style={thumbnailObj} />
        <Segment attached={true}>
          {innerStuff}
        </Segment>
      </Link>);
  }
}

export default RelatedBriefVideo;