import React, { Fragment } from 'react';
import { Responsive, Icon } from 'semantic-ui-react';
// user Context
import {withSpinner} from '../Context/withSpinner';

const Spinner = (props) => {
  const {show} = props.spinnerContext;
  // class used to restart CSS animation
  return show ? (
    <>
        <Responsive as={Fragment} maxWidth={640}>
            <Icon name='hourglass half' className='active' />
        </Responsive>
        <Responsive as={Fragment} minWidth={641}>
            <Icon name='hourglass half' size='huge' className='active' />
        </Responsive>
    </>
  ) : null;
}

export default withSpinner(Spinner);