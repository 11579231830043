import {createContext} from "react";
export const SpinnerContext = createContext(
  // default values used by a Consumer when it does not have a
  // matching Provider above it in the tree, useful for testing
  {
    spinnerContext: {
      show: false,
      showSpinner: () => {},
      hideSpinner: () => {},
    }
  }
);
