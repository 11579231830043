import React from 'react';
import { Modal, Segment } from 'semantic-ui-react';
import Buttons from './Buttons';

const ConfirmModal = (props) => {
  
  const datas = {};
  for(let key in props){
    if(key.slice(0,5)==='data-'){
      datas[key] = props[key];
    }
  }

  const confirmIcon = props.confirmIcon || 'remove';
  const confirmText = props.confirmText || '刪除';

  return (
    <Modal open={props.open} onClose={props.cancel}>
      <Modal.Content>
        <Segment basic>
          {props.description}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Buttons.CancelButton circular onClick={props.cancel} />
        {confirmIcon==='remove'
          ?<Buttons.DeleteButton
            circular
            content={confirmText}
            icon={confirmIcon}
            onClick={props.confirm}
            {...datas}
          />
          :<Buttons.SaveButton
            circular
            color='green'
            content={confirmText}
            icon={confirmIcon}
            onClick={props.confirm}
            {...datas}
          />
        }
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmModal;