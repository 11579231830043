import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import chunk from 'lodash/chunk';

/* import semantic-ui element */
import {
  Grid,
  Header,
  Responsive,
} from 'semantic-ui-react';

import BriefVideo from './BriefVideo';

const vidFields = ['id', 'sourceId', 'title', 'time', 'desc', 'views', 'creator_id', 'info'];

const VideoSegment = (props) => {
  const {
    title,
    titleLink,
    showAvatar,
    headerSize,
    cols = 4,
  } = props;

  let { videoList } = props;
  videoList = videoList.filter(x => x.status);
  if (props.limit) videoList = videoList.slice(0, props.limit);

  if (!videoList.length) {
    return false; //<div className="video-segment"></div>
  }

  return (
    <div className="video-segment">
      {titleLink ? <Link to={titleLink}><Header as="h1" size={headerSize || 'huge'}>{title}</Header></Link> : <Header as="h1" size={headerSize || 'huge'}>{title}</Header>}
      <Responsive as={Fragment} minWidth={640}>
        <Grid columns={cols} padded='vertically'>
          {chunk(videoList, cols).map(row => (
            <Grid.Row key={row.map(x => x.id)} stretched>
              {row.map(x => {
                const vidProps = { showAvatar }
                if (typeof (x) === "object") {
                  vidFields.forEach(y => {
                    if (y in x) {
                      vidProps[y] = x[y];
                    }
                  })
                  vidFields.key = x.id;
                } else {
                  vidProps.id = x;
                  vidProps.key = x;
                }
                return (<Grid.Column key={x.id || x}><BriefVideo {...vidProps} /></Grid.Column>);
              })}
            </Grid.Row>
          ))}
        </Grid>
      </Responsive>
      <Responsive as={Fragment} maxWidth={640}>
        {videoList.map(x => {
          const vidProps = { showAvatar }
          if (typeof (x) === "object") {
            vidFields.forEach(y => {
              if (y in x) {
                vidProps[y] = x[y];
              }
            });
            vidProps.key = x.id;
          } else {
            vidProps.id = x;
            vidProps.key = x;
          }
          return (<BriefVideo {...vidProps} />);
        })}
      </Responsive>
    </div>
  );
}

export default VideoSegment;