/* import layout */
import Layout from '../Layout';

/* import all modules componet */

import VideoPanel from '../Modules/Panel/VideoPanel';
import RecommendPanel from '../Modules/Panel/RecommendPanel';
import CategoryPanel from '../Modules/Panel/CategoryPanel';
import SearchPanel from '../Modules/Panel/SearchPanel';

// import NewestPanel from '../Modules/Panel/NewestPanel';
// import TodayFeaturedPanel from '../Modules/Panel/TodayFeaturedPanel';
// import MostStarsPanel from '../Modules/Panel/MostStarsPanel';
import GroupPanel2 from '../Modules/Panel/GroupPanel2';
import VideoByInfoPanel from '../Modules/Panel/VideoByInfoPanel';
// import MySubsPanel from '../Modules/Panel/MySubsPanel'

const RouterConfig = [

  //Main Landing Page
  {
    Path: '/recommended',
    Exact: false,
    Layout: Layout.Module,
    Content: RecommendPanel,
  },

  {
    Path: '/group/:superCategoryId',
    Exact: false,
    Layout: Layout.Module,
    Content: GroupPanel2,
  },

  {
    Path: '/category/:categoryId',
    Exact: false,
    Layout: Layout.Module,
    Content: CategoryPanel,
  },
  
  // {
  //   Path: '/newest',
  //   Exact: false,
  //   Layout: Layout.Module,
  //   Content: NewestPanel,
  // },

  // {
  //   Path: '/todayfeatured',
  //   Exact: false,
  //   Layout: Layout.Module,
  //   Content: TodayFeaturedPanel,
  // },

  // {
  //   Path: '/moststars',
  //   Exact: false,
  //   Layout: Layout.Module,
  //   Content: MostStarsPanel,
  // },

  // {
  //   Path: '/mysubs',
  //   Exact: false,
  //   Layout: Layout.Module,
  //   Content: MySubsPanel,
  // },

  {
    Path: '/search',
    Exact: false,
    Layout: Layout.Module,
    Content: SearchPanel,
  },

  //Single Video Page
  {
    Path: '/video/:videoId',
    Exact: false,
    Layout: Layout.Video,
    Content: VideoPanel,
  },

  //User Panel (own) & user/:userId (others)
  {
    Path: '/userpanel',
    Layout: Layout.User,
    Content: null, //Henry takes over
  },
  {
    Path: '/user/:userId',
    Layout: Layout.User,
    Content: null, //Henry takes over
  },
  {
    Path: '/systemPanel',
    Layout: Layout.System,
    Content: null, //Henry takes over
  },
  {
    Path: '/editVideo/:videoId',
    Layout: Layout.User,
    Content: null, //Henry takes over
  },
  {
    Path: '/:type/:title',
    Exact: false,
    Layout: Layout.Module,
    Content: VideoByInfoPanel,
  },
  // {
  //   Path: '/myProfile',
  //   Layout: Layout.User,
  //   Content: null, //Henry takes over
  // },
  {
    Path: '/addVideo',
    Layout: Layout.User,
    Content: null, //Henry takes over
  },
]

export default RouterConfig;