import React from 'react';
import {VideoSegment, EmptyTableMsg, _Component} from '../../Components';

import { get } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import { grades, difficulties } from '../../Const';

import { Form, Input, Grid, Table, Header, Checkbox, Responsive } from 'semantic-ui-react';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import throttle from 'lodash/throttle';

class SearchPanel extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "搜尋結果",
      videos: [],
      categoryBySuper: [],
      systemCat: [],
      filters: {
        str: '',
        category: new Set(),
        grade: new Set(),
        difficulty: new Set(),
        others: new Set(),
      },
      catVids: {},
      finishedLoading: false,
    };
    this.noPadding = {padding: 0};
    this.marginLeft = {marginLeft: "-1rem"};
    this.minInputLength = {minWidth: "16rem"};
    this.throttledInputChange = throttle(this.inputChange)
  }

  componentDidMount = async () => {
    try{
      const [videos, categoryBySuper, cat_vid, systemCat] = await Promise.all([
        this.assignPromise(get('getVideo'),'getVideo'),
        this.assignPromise(get('getCategoryForNav'),'getCat'),
        this.assignPromise(get('getAllVideoCategory'),'getVidCat'),
        this.assignPromise(get('getSystemCategory'),'getSystemCat'),
      ]) ;
      this._setState({
        videos: videos.filter(x=>x.status),
        categoryBySuper,
        systemCat,
        catVids: mapValues(groupBy(cat_vid, 'category_id'), x=>new Set(x.map(y=>y.video_id))),
        finishedLoading: true,
      });
    }catch(err){
      if (!this.isCancelled(err)) console.log("Error when fetching videos", err);
    }
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {target} = event;
    let name, value;
    switch(target.nodeName){
      case "LABEL": name = target.previousSibling.name; value = target.previousSibling.value; break;
      case "INPUT": 
        name = target.name; value = target.value; break;
      case "DIV": name = target.firstChild.name; value = target.firstChild.value; break;
      default: return;
    }
    const {dataset: {inputType}} = target.closest('.ui, button');
    let val = inputHandler(inputType, data);
    const {filters} = this.state;
    if(typeof filters[name] === 'object'){
      const newSet = new Set(filters[name]);
      if(val){
        newSet.add(Number.isInteger(+value)?+value:value);
      }else{
        newSet.delete(Number.isInteger(+value)?+value:value);
      }
      this.setState({
        filters: {...filters, [name]: newSet}
      })
    }else{
      this.setState({
        filters: {...filters, [name]: val}
      })
    }
    this.setState({ [name]: val })
  }

  render() {

    const {
      title,
      videos,
      systemCat,
      filters,
      categoryBySuper,
      catVids,
      finishedLoading
    } = this.state;

    let trueVideos = videos.filter(x=>{
      return (
        !filters.str || (x.title+"\b"+x.id).toUpperCase().indexOf(filters.str.toUpperCase())>=0
      ) && (
        !filters.grade.size || Array.from(filters.grade).some(y=>x.info.grade[y])
      ) && (
        !filters.difficulty.size || Array.from(filters.difficulty).some(y=>x.info.difficulty[y])
      ) && (
        !filters.category.size || Array.from(filters.category).some(y=>catVids[y] && catVids[y].has(x.id))
      ) && (
        !filters.others.size || Array.from(filters.others).every(y=>x.info[y])
      )
    });

    let trueContent;
    if (trueVideos.length) {
      trueContent = (<>
      <Responsive minWidth={1860}>
        <VideoSegment
          title={title}
          videoList={trueVideos}
          showAvatar={false}
          headerSize='large'
        />
      </Responsive>
      <Responsive minWidth={1400} maxWidth={1859}>
        <VideoSegment
          title={title}
          videoList={trueVideos}
          showAvatar={false}
          headerSize='large'
          cols={3}
        />
      </Responsive>
      <Responsive maxWidth={1399}>
        <VideoSegment
          title={title}
          videoList={trueVideos}
          showAvatar={false}
          headerSize='large'
          cols={2}
        />
      </Responsive>
    </>);
    } else {
      trueContent = (
        <div className="video-segment">
          <Header as="h1" size='large'>{title}</Header>
          <Table basic><Table.Body>{<EmptyTableMsg msg={finishedLoading?'找不到影片':'資料載入中'} />}</Table.Body></Table>
        </div>
      );
    }

    return (
      <div className='video-segment'>
        <Grid padded='vertically' className='extra'>
          <Grid.Row divided id='search-panel'>
            <Grid.Column computer={5} tablet={16} mobile={16}>
              <div className="video-segment">
                <Header as='h1' size='large'>進階搜尋</Header>
                <Form style={this.noPadding}>
                  {categoryBySuper.map(sup=>(
                    <Form.Group key={sup.id}>
                      <Form.Field>
                        <Header as='h2' size='medium'>{sup.displayName}</Header>
                        {(sup.items||[]).map(cat=><Checkbox
                          label={cat.displayName}
                          key={cat.id}
                          value={cat.id}
                          checked={filters.category.has(cat.id)}
                          name='category'
                          data-input-type='checkbox'
                          onChange={this.throttledInputChange}
                        />)}
                      </Form.Field>
                    </Form.Group>
                  ))}
                  <Form.Group>
                    <Form.Field>
                      <Header as='h2' size='medium'>適合年級</Header>
                      {grades.map((g,i)=><Checkbox
                        label={g}
                        key={g}
                        value={i}
                        checked={filters.grade.has(i)}
                        name='grade'
                        data-input-type='checkbox'
                        onChange={this.throttledInputChange}
                      />)}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <Header as='h2' size='medium'>難易程度</Header>
                      {difficulties.map((d,i)=><Checkbox
                        label={d}
                        key={d}
                        value={i}
                        checked={filters.difficulty.has(i)}
                        name='difficulty'
                        data-input-type='checkbox'
                        onChange={this.throttledInputChange}
                      />)}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group grouped>
                    <Header as='h2' size='medium'>其他</Header>
                    <Form.Field>
                      <Checkbox
                        label="支援線上授課"
                        value="canOnline"
                        checked={filters.others.has("canOnline")}
                        name='others'
                        data-input-type='checkbox'
                        onChange={this.throttledInputChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        label="可使用平板上課"
                        value="canTablet"
                        checked={filters.others.has("canTablet")}
                        name='others'
                        data-input-type='checkbox'
                        onChange={this.throttledInputChange}
                      />
                    </Form.Field>
                    {(systemCat||[]).map(cat=>(
                      <Form.Field>
                        <Checkbox
                          label={cat.displayName}
                          key={cat.id}
                          value={cat.id}
                          checked={filters.category.has(cat.id)}
                          name='category'
                          data-input-type='checkbox'
                          onChange={this.throttledInputChange}
                        />
                      </Form.Field>
                    ))}
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <Header as='h2' size='medium'>標題或專題編號</Header>
                      <Input
                        value={filters.str}
                        name='str'
                        data-input-type='text'
                        onChange={this.throttledInputChange}
                        placeholder='請輸入影片標題或專題編號'
                        style={this.minInputLength}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </div>
            </Grid.Column>
            <Grid.Column computer={11} tablet={16} mobile={16}>
              {trueContent}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default SearchPanel;




