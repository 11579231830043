import React from 'react';
import { Table } from 'semantic-ui-react';

const EmptyTableMsg = ({ colSpan = 1, msg = '沒有記錄' }) => (
  <Table.Row>
    <Table.Cell colSpan={colSpan}>
      <b>{msg}</b>
    </Table.Cell>
  </Table.Row>
)

export default EmptyTableMsg;