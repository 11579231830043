import React from "react";
import { UserContext } from "./user-context";
import { _Component } from "../Components";
import { get, post, postWithError } from "../Helper/ApiHelper";

export default class UserProvider extends _Component {
  constructor() {
    super();
    this.default = {
      id: null,
      email: '',
      name: '',
      classCode: '',
      classNo: '',
      desc: '',
      enabled: null,
      imgUrl: 'blank.png',
      lastLogin: null,
      position_id: null,
      sessionId: null,
    };
    this.state = {
      getPermission: this.getPermission,
      setUser: this.setUser,
      resetUser: this.resetUser,
      pingUser: this.pingUser,
      loggedInUser: this.default,
      userValid: null,
    }
  }

  componentDidMount = () => {
    this.mounted = true;
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  getPermission = (item) => {
    const { permission } = this.state;
    return permission && permission[item];
  }

  treatUser = async (user) => {
    const data = {
      userValid: false,
      permission: undefined,
      loggedInUser: {},
    };
    if (user && (typeof user !== 'object' || user.err !== '此Session已過期')) {
      if (typeof user === 'object') {
        if (Array.isArray(user.result) && user.result.length) {
          const res = user.result[0];
          if (res.enabled) {
            data.userValid = true;
            data.loggedInUser = res;
            return this.assignPromise(get(`getPosition/${res.position_id}`), 'pingPosition').then(position => {
              if (Array.isArray(position) && position.length) {
                data.permission = position[0];
              }
              return data;
            }).catch(console.log);
          } else {
            alert('用戶被標示為已停用！請與負責人員聯繫');
          }
        } else {
          alert(user.err || '用戶不存在！請通知負責人員為您新增帳號');
        }
      } else {
        alert('用戶不存在！請通知負責人員為您新增帳號');
      }
    }
    return Promise.resolve(data);
  }

  pingUser = async (access_token) => {
    let p;
    if (access_token) {
      p = this.assignPromise(postWithError("signIn", { token: access_token }), 'signIn');
    } else {
      p = this.assignPromise(post("resumeSession"), 'resumeSession');
    }
    try {
      const res = await p;
      const dataFill = await this.treatUser(res);
      await this._setState(dataFill);
      return res;
    } catch (err) {
      try {
        const dataFill = await this.treatUser();
        await this._setState(dataFill);
        return err;
      } catch (err) {
        console.log("Ping 時發生錯誤", err);
      }
    }
  }

  setUser = (loggedInUser) => {
    if (this.mounted) this.setState({ loggedInUser });
  }

  resetUser = async () => {
    try {
      await this.assignPromise(post('signOut'), 'signOut');
    } catch (err) {
      if (!this.isCancelled(err)) {
        alert("重設用戶時發生錯誤");
        console.log("重設用戶時發生錯誤", err)
      }
    } finally {
      this.setState({
        loggedInUser: this.default,
        userValid: false,
      });
    }
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          userContext: {
            ...this.state
          }
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
