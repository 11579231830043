import React from 'react';
import {
  Segment,
  Header,
  Icon,
} from 'semantic-ui-react';

const EmptySegmentMsg = ({ icon = 'warning', msg = '找不到', loading = false }) => {
  if (loading) return (
    <Segment basic textAlign='center' padded>
      <Header as='h2' icon><Icon loading name='spinner' /></Header>
    </Segment>
  )
  return (
    <Segment basic textAlign='center' padded>
      <Header as='h2' icon>
        <Icon name={icon} />
        {msg}
      </Header>
    </Segment>
  )
}

export default EmptySegmentMsg;