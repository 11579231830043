/* import moment */
import moment from 'moment';

/* Moment To Database Format */
export const momentToDbFormat = (date) => {
  return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
}

/* Moment To Date */
export const momentToDate = (date) => {
  return moment(date).format('DD/MM/YYYY');
}

/* Date To Moment */
export const dateToMoment = (date) => {
  return moment(date);
}

/* Moment now */
export const now = () => {
  return moment();
}

/* File Timestamp */
export const fileTimeStamp = () => {
  return moment().format('YYYYMMD-HHmmss');
}

export const timeToDescription = (t) => {
  return moment(t).format('YYYY年M月D日');
}

export const nextWeekday = (dayINeed) => {
  const today = moment().isoWeekday();
  return timeToDescription((today <= dayINeed ? moment() : moment().add(1, 'weeks')).isoWeekday(dayINeed));
}

export const isSameWeek = (a, b = moment()) => {
  const interval = b.diff(a, 'seconds');
  return interval < 60 * 60 * 24 * 7;
}