/* import react */
import React from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  _Component,
  EmptySegmentMsg
} from '../../Components';

import { get } from '../../Helper/ApiHelper';
import CategoryPanel from './CategoryPanel';

class RecommendPanel extends _Component {
  constructor(props) {
    super(props);

    this.state = {
      superCategoryId: 1,
      finishedLoading: false,
      cats: [],
    };
  }
  getAllInfo = async () => {
    try{
      const {superCategoryId} = this.state;
      const cats = await this.assignPromise(get('getCategoryBySuper/' + superCategoryId), 'getCat');
      await this._setState({
        finishedLoading: true,
        cats,
      })
    }catch(err){
      if(err && typeof err ==='object' && err.isCanceled) return;
      console.log("Error when fetching info for recommended panel", err);
    }
  };

  componentDidMount() {
    this.getAllInfo();
  }

  render() {

    const {
      cats,
      finishedLoading
    } = this.state;

    if (this.props.match.params.superCategoryId && finishedLoading && !cats.length) {
      return (<div><EmptySegmentMsg msg='此分組未有任何類別' /></div>);
    }

    return cats.map(cat => (
      <CategoryPanel
        key={cat.id}
        category_id={cat.id}
        title={cat.disdplayName}
        showAvatar={false}
        limit={4}
        titleLink={'/category/'+cat.id}
      />
    ))
  }
}

export default withRouter(RecommendPanel);