/* import react */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from '../../Helper/ApiHelper';

/* import semantic-ui element */
import {
  Grid,
} from 'semantic-ui-react';

/* import components */
import {FullVideo,RelatedVideoSegment,_Component,EmptySegmentMsg} from '../../Components';

class VideoPanel extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      hasVideo: null,
      id: props.match.params.videoId,
      inProgress: null,
      relatedVideos: [],
    }
  }

  getAllInfo = async () => {
    try{
      const video_id = this.state.id;
      if (video_id) {
        const [vid, relatedVideos] = await Promise.all([
          this.assignPromise(get('getVideo/id/' + video_id), 'getVideo'),
          this.assignPromise(get('getRelatedVideo/' + video_id), 'getRelatedVideo'),
        ]);
        await this._setState({
          inProgress: 'complete',
          hasVideo: Array.isArray(vid) && !!vid.length,
          relatedVideos
        });
      }
    }catch(err){
    }
  }

  componentDidMount() {
    this.getAllInfo();
  }

  componentDidUpdate = async (prevProps) => {
    const newId = this.props.match.params.videoId;
    const oldId = prevProps.match.params.videoId;
    if (newId !== oldId) {
      try{
        await this._setState({ id: newId });
        await this.getAllInfo();
      }catch(err){

      }
    }
  }

  render() {
    const {
      id,
      relatedVideos,
      hasVideo,
      inProgress
    } = this.state;

    let videoContent = false;

    if (hasVideo === false) {
      videoContent = <EmptySegmentMsg msg='此影片不存在，或已被刪除！' />;
    }

    if (hasVideo === true) {
      switch (inProgress) {
        case 'complete':
          videoContent = <FullVideo videoId={id} />;
          break;
        case 'in_progress':
          videoContent = <EmptySegmentMsg msg='影片尚在轉碼中，請稍後再試。' />;
          break;
        case 'error':
          videoContent = <EmptySegmentMsg msg='獲取影片時發生錯誤！' />;
          break;
        default: break;
      }
    }

    const additionalContent = !!id && hasVideo && (
      <RelatedVideoSegment
        title="相關作品"
        videoList={relatedVideos}
      />
    );

    return (
      <div className="fullwidth">
        <Grid columns={2} padded='vertically' centered stretched>
          <Grid.Column width={16} className='video-panel-video'>
            {videoContent}
          </Grid.Column>
          <Grid.Column className='related-videos'>
            {additionalContent}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(VideoPanel);