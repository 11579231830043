import React from 'react';
/* import semantic-ui element */

const SquareLabel = (props) => {
  const { imgPath, wrapperProps } = props;
  return (
    <div className="square-thumbnail-wrap" {...wrapperProps}>
      <div className="square-thumbnail" style={{
        'backgroundImage': `url(${imgPath})`,
      }} />
    </div>
  );
}
export default SquareLabel;