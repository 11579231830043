/* import react */
import React, { Fragment } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

/* import module nav */
import { Avatar, EmptySegmentMsg, _Component } from "../Components";

import { withUser } from '../Context/withUser';
import { withSpinner } from '../Context/withSpinner';

import { get, post, postWithError } from '../Helper/ApiHelper';

import User from '../Modules/User';

/* import semantic-ui element */
import {
  Grid,
  Button,
  Header,
  Menu,
  Responsive,
} from 'semantic-ui-react';


class UserPanelLayout extends _Component {
  constructor(props) {
    super(props);
    const isOwnUser = !props.match.params.userId || (props.match.params.userId == this.getCurrentUserId());
    const pronoun = isOwnUser ? '我' : '他/她';

    this.state = {
      userId: props.match.params.userId || this.getCurrentUserId(),
      hasUser: null,
      name: '',
      classCode: '',
      imgUrl: '/avatars/blank.png',
      desc: '',
      position_id: null,
      menuData: [],
      isOwnUser,
      isSub: false,
      pronoun,
      routerPath: isOwnUser ? '/userpanel' : '/user/:userId'
    }

    props.userContext.pingUser().then(result=>{
      if(!result){
        props.history.replace('/');
      }
    })
  }

  getAllInfo = async () => {
    try{
      const { userId } = this.state;
      const data = {
        menuData: this.genMenu(userId)
      };

      const [user, subResult] = await Promise.all([
        this.assignPromise(get('getUser/' + userId), 'getUser'),
        this.assignPromise(get('getSubscription/both/' + this.getCurrentUserId() + '/' + userId), 'getSubStatus'),
      ]);
      if(Array.isArray(user) && user.length){
        Object.assign(data, {
          hasUser: true,
          name: user[0].name,
          classCode: user[0].classCode,
          imgUrl: user[0].imgUrl,
          desc: user[0].desc,
          position_id: user[0].position_id,
          isSub: !!subResult.length
        });
        await Promise.all(data.menuData.map(x=>(
          this.assignPromise(get(x.getFrom + userId), x.displayName).then(res=>{
            if (Array.isArray(res)) {
              if (!this.state.isOwnUser && x.getFrom.indexOf('Subscription') === -1)
                res = res.filter(x => x.status);
              x.count = x.id==='comments'?res.reduce((prev,cur)=>cur.content.length+prev,0):res.length;
            }
          })
        )));
        await this._setState(data);
      }
    }catch(err){
      if (!this.isCancelled(err)) console.log("Error when get all info", err);
    }
  };

  componentDidMount() {
    this.getAllInfo();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      //alert('User Panel Layout Update!');
      this.props.userContext.pingUser();
    }
    if (this.state.hasUser) {
      const { loggedInUser } = this.props.userContext;

      const newId = this.props.match.params.userId || this.getCurrentUserId();
      const oldId = prevProps.match.params.userId || prevProps.userContext.loggedInUser.id;
      if (newId !== oldId) {
        this.setState({
          userId: newId,
          isOwnUser: +newId === +this.getCurrentUserId()
        }, this.getAllInfo);
      } else {
        this.assignPromise(get('getUser/' + newId), 'getUser').then(x => {
          if (!x || !Array.isArray(x) || (!x[0].enabled && x[0].position_id)) {
            this._setState({ hasUser: false });
          } else {
            if (['desc', 'imgUrl'].some(x => loggedInUser[x] !== prevProps.userContext.loggedInUser[x])) {
              this._setState({
                desc: loggedInUser.desc,
                imgUrl: loggedInUser.imgUrl
              })
            }
          }
        }).catch(_r => { })
      }
    }
  }

  genMenu = (user_id) => {
    const curPath = this.state.isOwnUser ? `/userpanel` : `/user/${user_id}`;

    return [
      {
        id: 'uploaded_video',
        path: curPath + '/',
        displayName: `影片`,
        getFrom: 'getVideo/user/',
        count: null,
      },
      {
        id: 'favs',
        path: curPath + '/fav',
        displayName: `讚好`,
        getFrom: 'getUserFavVideo/',
        count: null,
      },
      {
        id: 'ratings',
        path: curPath + '/ratings',
        displayName: `評分`,
        getFrom: 'getRatingVideo/user/',
        count: null,
      },
      {
        id: 'comments',
        path: curPath + '/comments',
        displayName: `評論`,
        getFrom: 'getCommentVideo/user/',
        count: null,
      },
      // {
      //   path: curPath + '/fans',
      //   displayName: `粉絲`,
      //   getFrom: 'getSubscription/videoOwner/',
      //   count: null,
      // },
      // {
      //   path: curPath + '/subs',
      //   displayName: `訂閱`,
      //   getFrom: 'getSubscription/subscriber/',
      //   count: null,
      // }
    ];
  }

  genButtonGroup = (isOwnUser, isSub, isVertical) => {
    return (
      <Button.Group vertical={isVertical}>
        {isOwnUser && this.props.userContext.loggedInUser.position_id && !this.props.match.params.videoId && this.props.match.path !== '/addVideo' && <Button color="blue" href='/addVideo' onClick={this.navigation}>新增影片</Button>}
        {/* {isOwnUser?(
        <>
          <Link to='/myProfile'><Button color="red">個人設定</Button></Link>
          <Link to='/addVideo'><Button color="red">新增影片</Button></Link>
        </>):(!!this.state.hasUser && !!this.props.userContext.userValid && (
          <Button
            color={isSub ? "blue" : "red"}
            onClick={this.toggleSub}
            data-status={isSub ? '' : '1'}
            type='button'
          >{isSub ? "取消" : "立即"}訂閱</Button>
        ))} */}
        <Button color="green" href='/recommended' onClick={this.navigation}>返回主頁</Button>
      </Button.Group>
    );
  }

  navigation = (eventOrRoute) => {
    if(eventOrRoute){
      if(typeof eventOrRoute === 'string'){
        this.props.history.push(eventOrRoute);
        return;
      }
      const {href} = eventOrRoute.target.closest('.item, .ui.button');
      this.props.history.push('/'+href.split('/').slice(3).join('/'));
      eventOrRoute.preventDefault();
    }
  }

  toggleSub = (event) => {
    const {status} = event.target.closest('button').dataset;
    let p;
    const { userId } = this.state;
    const data = {
      subscriber: this.getCurrentUserId(),
      video_owner: userId,
    }
    if (status) {
      p = this.assignPromise(post('addSubscription', data), 'addSub');
    } else {
      p = this.assignPromise(post('deleteSubscription', data), 'delSub');
    }
    p.then((res) => {
      if (res) {
        return this._setState({
          isSub: !!status
        }).then(this.recheckFans);
      }
    }).catch(_r => { });
  }

  recheckVids = () => {
    const menuData = [...this.state.menuData];
    const { userId } = this.state;

    this.assignPromise(get(menuData[0].getFrom + userId), 'getVid').then((res) => {
      if (Array.isArray(res)) {
        menuData[0] = {
          ...menuData[0],
          count: res.length
        };
      }
      return this._setState({
        menuData
      });
    }).catch(_r => { });
  }

  recheckFans = () => {
    const menuData = [...this.state.menuData];
    const { userId } = this.state;

    this.assignPromise(get(menuData[3].getFrom + userId), 'getFan').then((res) => {
      if (Array.isArray(res)) {
        menuData[3] = {
          ...menuData[3],
          count: res.length
        };
      }
      return this._setState({
        menuData
      });
    }).catch(_r => { });
  }

  addVideo = (data) => {
    this.props.history.push('/userpanel/');
    postWithError('editVideo/', data).then((_res) => {
      if (_res.status) {
        if (window.confirm(data.id?'編輯影片成功！':'新增影片成功！')) {
          this.props.history.push('/recommended');
          this.props.history.replace('/userpanel/');
        }
      }else{
        return Promise.reject(_res);
      }
      // this.props.spinnerContext.hideSpinner(() => {
      //   if (_res.status) {
      //     if (window.confirm('上載成功！您要前往用戶設定嗎？')) {
      //       this.props.history.push('/recommended');
      //       this.props.history.replace('/userpanel/');
      //     }
      //   } else {
      //     if (_res.err.indexOf('4101') !== -1) {
      //       const nextDate = nextWeekday(4);
      //       alert(`抱歉！學校今星期的用量已用完\n無法上載您的影片\n請在星期四(${nextDate})下午一時後再試。`);
      //     } else {
      //       alert(`上載失敗\n${_res.err}`);
      //     }
      //   }
      // });
    }).catch((_res) => {
      console.log('failure', _res);
      alert('新增影片時發生錯誤！');
    });
    // alert('我們現在會上載您的影片，在上載完成前新的影片不會顯示在「我的影片」中');
    // this.props.spinnerContext.showSpinner();
  }

  delVideo = (videoId) => {
    return this.assignPromise(post('deleteVideo', { id: videoId }), 'delVideo');
  }

  render() {
    const {
      hasUser,
      name,
      classCode,
      imgUrl,
      desc,
      menuData,
      isOwnUser,
      isSub,
      pronoun,
      routerPath,
      userId,
      position_id,
    } = this.state;


    const {
      location: { pathname },
      match,
    } = this.props;

    if (match.params.userId && isOwnUser)
      return <Redirect to='/userpanel/' />

    if (hasUser === null) {
      return (
        <>
          <Grid className='user-panel-header' centered>
            <Responsive as={Fragment} maxWidth={640}>
              <Grid.Row>
                <Grid.Column width={4} />
                <Grid.Column width={12} className="user-desc">
                  <Header as="h2" id="user-header">簡介</Header>
                  <div className="desc-text">{desc}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} textAlign='center'>
                  {this.genButtonGroup(isOwnUser, isSub, false)}
                </Grid.Column>
              </Grid.Row>
            </Responsive>
            <Responsive as={Fragment} minWidth={641}>
              <Grid.Column width={4} textAlign="center" />
              <Grid.Column width={8} className="user-desc">
                <Header as="h2" id="user-header">簡介</Header>
                <div className="desc-text">{desc}</div>
              </Grid.Column>
              <Grid.Column width={4} textAlign="center">
                {this.genButtonGroup(isOwnUser, isSub, true)}
              </Grid.Column>
            </Responsive>
          </Grid>
          <Menu size='huge' fluid borderless secondary widths={menuData.length || 1} />
          <Grid className='content-padding user-panel-no-data' id='main' centered />
        </>
      );
    }

    if (hasUser === false) {
      return (
        <>
          <Grid className='user-panel-header' centered>
            <Responsive as={Grid.Row} maxWidth={640}>
              <Grid.Column width={16} textAlign='center'>
                {this.genButtonGroup(isOwnUser, isSub, false)}
              </Grid.Column>
            </Responsive>
            <Responsive as={Grid.Row} minWidth={641}>
              <Grid.Column width={16} textAlign="center">
                {this.genButtonGroup(isOwnUser, isSub, true)}
              </Grid.Column>
            </Responsive>
          </Grid>
          <Grid className='content-padding user-panel-no-data' id='main' centered>
            <EmptySegmentMsg msg='用戶不存在' />
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid className='user-panel-header' centered>
          <Responsive as={Fragment} maxWidth={640}>
            <Grid.Row>
              <Grid.Column width={4}>
                <Avatar
                  {...{ imgUrl, name, classCode, position_id }}
                  size="tiny"
                  headerSize="h4"
                />
              </Grid.Column>
              <Grid.Column width={12} className="user-desc">
                <Header as="h2" id="user-header">簡介</Header>
                <div className="desc-text">{desc}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign='center'>
                {this.genButtonGroup(isOwnUser, isSub, false)}
              </Grid.Column>
            </Grid.Row>
          </Responsive>
          <Responsive as={Fragment} minWidth={641}>
            <>
              <Grid.Column width={4} textAlign="center">
                <Avatar
                  {...{ imgUrl, name, classCode, position_id }}
                  size="tiny"
                  headerSize="h3"
                />
              </Grid.Column>
              <Grid.Column width={8} className="user-desc">
                <Header as="h2" id="user-header">簡介</Header>
                <div className="desc-text">{desc}</div>
              </Grid.Column>
            </>
            <Grid.Column width={4} textAlign="center">
              {this.genButtonGroup(isOwnUser, isSub, true)}
            </Grid.Column>
          </Responsive>
        </Grid>
        <Menu size='huge' fluid borderless secondary widths={menuData.length || 1}>
          <Responsive as={Fragment} maxWidth={480}>
            {menuData.map(({ displayName, count, path }, index) => {
              const newName = (<span>
                {displayName}<br />{count == null ? '' : count}
              </span>);
              return (
                <Menu.Item key={index} active={pathname === path} href={path} onClick={this.navigation}>
                  {newName}
                </Menu.Item>
              )
            })}
          </Responsive>
          <Responsive as={Fragment} minWidth={481} maxWidth={640}>
            {menuData.map(({ displayName, count, path }, index) => {
              const newName = (<span>
                {pronoun + '的' + displayName}<br />{count == null ? '' : count}
              </span>);
              return (
                <Menu.Item key={index} active={pathname === path} href={path} onClick={this.navigation}>
                  {newName}
                </Menu.Item>
              )
            })}
          </Responsive>
          <Responsive as={Fragment} minWidth={641}>
            {menuData.map(({ displayName, count, path }, index) => {
              const newName = pronoun + '的' + displayName + '：' + (count == null ? '' : count);
              return (
                <Menu.Item key={index} active={pathname === path} href={path} onClick={this.navigation}>
                  {newName}
                </Menu.Item>
              )
            })}
          </Responsive>
        </Menu>
        <Grid className='content-padding' id='main' centered>
          <Switch>
            <Route
              path={`${routerPath}/`}
              exact
              render={() => <User.Videos userId={userId} id='user-vid' />}
            />
            <Route
              path={`${routerPath}/fav`}
              render={() => <User.Fav userId={userId} id='user-fav' />}
            />
            <Route
              path={`${routerPath}/ratings`}
              render={() => <User.Ratings userId={userId} id='user-rate' />}
            />
            <Route
              path={`${routerPath}/comments`}
              render={() => <User.Comments userId={userId} id='user-comm' />}
            />
            {/* <Route
              path={`${routerPath}/fans`}
              render={() => <Fans userId={userId} isSub={isSub} />}
            />
            <Route
              path={`${routerPath}/subs`}
              render={() => <Subs userId={userId} />}
            />
            <Route
              path={`/myProfile`}
              render={() => <ProfileSetting saveProfile={this.props.saveProfile} />}
            />*/}
            <Route
              path={`/editVideo/:videoId`}
              render={() => <User.AddVideo addVideo={this.addVideo} delVideo={this.delVideo} />}
            />
            <Route
              path={`/addVideo`}
              render={() => <User.AddVideo addVideo={this.addVideo} />}
            />
          </Switch>
        </Grid>
      </>
    );
  }
}

export default withRouter(withSpinner(withUser(UserPanelLayout)));