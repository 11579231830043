import React, {memo} from 'react';
import { isSameWeek } from '../Helper/TimeHelper';

const NewLabel = (props) => {
  const isNew = isSameWeek(props.time);
  const { isGridView } = props;
  return !!(isGridView || isNew) && (
    <span className={[isNew ? 'new-label' : 'old-label', props.className].join(" ")}>
      {isNew ? "New" : " "}
    </span>
  );
}
export default memo(NewLabel);