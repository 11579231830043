import React from 'react';
import uniqBy from 'lodash/uniqBy';

import {KOLStarRatings, UserVideo, _Component, EmptySegmentMsg} from '../../Components';
import { get } from '../../Helper/ApiHelper';

class UserPanel_Rating extends _Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: null,
    };
    this.ratingRow = [{
      displayName: '評分：',
      content: x=>(<KOLStarRatings
        rating={+(x.stars)}
        starDimension='1rem'
        starSpacing='0.125rem'
      />)
    }]
  }

  componentDidMount() {
    this.assignPromise(get('getRatingVideo/user/' + this.props.userId), 'getUserRating').then(res => {
      return this._setState({
        videos: uniqBy(res, 'video_id') || []
      })
    }).catch(_r => { });
  }

  render() {

    const {
      videos,
    } = this.state;

    if(!videos) return <EmptySegmentMsg loading={true}/>
    if(!videos.length) return <EmptySegmentMsg msg="此用戶未有為任何影片評分"/>

    return (
      <>
        {videos.map(x => (
          <UserVideo
            key={x.video_id}
            video={x}
            extraRows={this.ratingRow}
          />
        ))}
      </>
    )
  }
}

export default UserPanel_Rating;